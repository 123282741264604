export const TOTAL_REGISTRATION_STEPS = 4

export const HIDE_SPLASH_PAGE_PERMANENTLY = 'HIDE_SPLASH_PAGE_PERMANENTLY'
export const SKIPPED_SPLASH_PAGE = 'SKIPPED_SPLASH_PAGE'

export const MAX_GEO_POS_ERROR_CODE = 3
export const ORION_ADDRESS_LAT = -20.2280242
export const ORION_ADDRESS_LNG = 57.5377406

export const SUPPORTED_IMAGE_EXTENSIONS = ['jpeg', 'jpg', 'png', 'webp']
export const MAX_IMAGE_COMPRESSED_SIZE_IN_MB = 2

export const DEFAULT_COUNTRY_CODE = 'MU'
