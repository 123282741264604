import styled from 'styled-components'

export const StyledWrapper = styled.main`
  margin: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  padding: 1rem;
`

export const StyledErrorH1 = styled.h1`
  width: 100%;
  max-width: 100%;
  text-align: center;
  font-weight: 400;
  font-size: 1.5rem;
  font-style: italic;
  color: ${({theme}) => theme.colors.lunarGray30};
`

export const StyledErrorBody = styled.h1`
  width: 100%;
  max-width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 1rem;
  color: ${({theme}) => theme.colors.black};
  margin: 0 0 1rem 0;
`
