import {Trans, useTranslation} from 'react-i18next'
import PackageCards from './components/PackageCards'
import {StyledInstructions1, StyledInstructions2} from './styles'

export default function Step1({packages}) {
  const {t} = useTranslation()

  return (
    <>
      <Trans
        i18nKey="userMessagesUnread"
        components={{
          paragraph1: <StyledInstructions1 />,
          bold: <strong />,
          paragraph2: <StyledInstructions2 />,
        }}
      >
        {t('registrationPage:step1.instructions')}
      </Trans>
      <PackageCards name="packageId" packages={packages} />
    </>
  )
}
