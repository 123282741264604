import styled from 'styled-components'
import {forwardRef} from 'react'
import clickableStyles from './styles/clickableStyles'
import Button from './Button'
import Anchor from './Anchor'

const StyledClickable = styled.button`
  ${clickableStyles()}
`

const Clickable = forwardRef(({type, children, ...props}, ref) => (
  <StyledClickable type={type} ref={ref} {...props}>
    {children}
  </StyledClickable>
))

export default Clickable
export {Button, Anchor}
