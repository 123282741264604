import {StyledSvg} from './styles'

function DownwardArrowIcon({size, color, width, height, ...props}) {
  return (
    <StyledSvg
      viewBox="0 0 11 9"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      $size={size}
      $width={width}
      $height={height}
      {...props}
    >
      <path d="M5.5 9 .737.75h9.526L5.5 9Z" fill={color || '#000'} />
    </StyledSvg>
  )
}

export default DownwardArrowIcon
