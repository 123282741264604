import styled from 'styled-components'

const StyledContainer = styled.div`
  background-color: ${({theme}) => theme.colors.white};
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledMain = styled.main`
  width: 100%;
  padding: ${({$noBottomPadding}) =>
    $noBottomPadding ? '1rem 1rem 0 1rem' : '1rem'};
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledLoaderWrapper = styled.div`
  display: flex;
  min-height: 100%;
  justify-content: center;
  align-items: center;
  padding: 16px;
`

export {StyledContainer, StyledMain, StyledLoaderWrapper}
