import {StyledSvg} from './styles'

function FacebookIcon({size, color, width, height, ...props}) {
  return (
    <StyledSvg
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      $size={size}
      $width={width}
      $height={height}
      {...props}
    >
      <circle cx={20} cy={20} r={20} fill={color || '#3B5998'} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.315 12.958c-.623-.125-1.465-.218-1.995-.218-1.433 0-1.526.623-1.526 1.62v1.776h3.583l-.312 3.678h-3.271V31h-4.488V19.814H15v-3.678h2.306v-2.275c0-3.116 1.465-4.861 5.142-4.861 1.277 0 2.212.187 3.427.436l-.56 3.522Z"
        fill="#fff"
      />
    </StyledSvg>
  )
}

export default FacebookIcon
