import {useCallback, Children, cloneElement} from 'react'
import {Menu, MenuItem} from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/index.css'
import '@szhsin/react-menu/dist/transitions/slide.css'

function CallWrappper({phoneNumbers, children}) {
  const showMenu = useCallback(() => phoneNumbers.length > 1, [phoneNumbers])

  const call = useCallback(
    num => e => {
      e?.preventDefault?.()

      const link = `tel:${num}`

      window.location.href = link
    },
    [],
  )

  const renderMenu = useCallback(
    () => (
      <Menu menuButton={children}>
        {phoneNumbers.map(num => (
          <MenuItem key={num} onClick={call(num)}>
            {num}
          </MenuItem>
        ))}
      </Menu>
    ),
    [call, children, phoneNumbers],
  )

  const renderCallBtn = useCallback(() => {
    const num = phoneNumbers[0]

    const clickableChildren = Children.map(children, child =>
      cloneElement(child, {onClick: call(num)}),
    )

    return clickableChildren
  }, [call, children, phoneNumbers])

  const renderContent = useCallback(
    () => (showMenu() ? renderMenu() : renderCallBtn()),
    [renderCallBtn, renderMenu, showMenu],
  )

  return renderContent()
}

export default CallWrappper
