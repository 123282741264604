import {useCallback, useEffect, useMemo, useState} from 'react'
import {useParams, Link} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {object} from 'yup'
import {toast} from 'react-toastify'
import DefaultLayout from '../../layouts/DefaultLayout'
import {
  PageHeading1,
  PageHeading2,
  StyledSearchContainer,
} from '../../styles/common'
import {getSubCategoriesByCategoryId} from '../../services/api'
import Search from '../../components/Search'
import {StyledLink, SubCategoriesContainer} from './styles'
import Card from '../../components/Card'

function CategoryPage() {
  const {id} = useParams()
  const {t} = useTranslation()
  const [isLoading, setIsLoading] = useState(true)
  const [categoryName, setCategoryName] = useState('')
  const [subCategories, setSubCategories] = useState([])
  const [suggestions, setSuggestions] = useState([])
  const [searchResults, setSearchResults] = useState([])

  useEffect(() => {
    let setState = (newCategoryName, newSubCategories) => {
      setCategoryName(newCategoryName)
      setSubCategories(newSubCategories)
      setSearchResults(newSubCategories)
      setSuggestions(
        newSubCategories.map(({_id, label}) => ({_id, label, value: label})),
      )
      setIsLoading(false)
    }

    getSubCategoriesByCategoryId(id)
      .then(res => {
        const {
          id: mainCategoryId,
          label: mainCategoryLabel,
          image,
          subCategories: subCategoryList,
        } = res
        // const catName = res?.[0]?.mainCategory?.label || ''
        // const subCats = res?.map(({_id, label}) => ({
        //   _id:,
        //   label,
        // }))
        const catName = mainCategoryLabel
        const subCats = subCategoryList.map((subCategoryLabel, index) => ({
          _id: Math.random(), // TODO: Replace with legitimate _id property
          label: subCategoryLabel.label,
        }))

        setState?.(catName, subCats || [])
      })
      .catch(e => {
        console.log('e :>> ', e)
        toast.error(t('common:errors.unknownRefresh'))
        setIsLoading(false)
      })

    return () => {
      setState = null
    }
  }, [id, t])

  const validationSchema = useMemo(
    () =>
      object({
        subCategory: object().required().nullable(),
      }),
    [],
  )

  const searchSuggestions = useMemo(
    () => [
      {
        key: 'subCategory',
        name: 'subCategory',
        options: suggestions,
        placeholder: t('categoryPage:search.placeholder'),
        $lighterBg: true,
        async: false,
        onClear: () => setSearchResults(subCategories),
      },
    ],
    [subCategories, suggestions, t],
  )

  const handleSubmit = useCallback(
    (values, {setSubmitting}) => {
      setSubmitting(false)
      const selectedSubCatId = values.subCategory._id
      const results = subCategories.filter(
        subCat => subCat._id === selectedSubCatId,
      )

      setSearchResults(results)
    },
    [subCategories],
  )

  const renderSubCategories = useCallback(() => {
    const pathname = () => `/offices`
    const urlParams = subCategory =>
      new URLSearchParams({
        what: subCategory.label,
      })
    const search = subCategory => `?${urlParams(subCategory).toString()}`

    return searchResults.map(subCategory => (
      <StyledLink
        as={Link}
        key={subCategory._id}
        to={{
          pathname: pathname(),
          search: search(subCategory),
        }}
      >
        <Card title={subCategory.label} textOnly />
      </StyledLink>
    ))
  }, [searchResults])

  return (
    <DefaultLayout loading={isLoading}>
      <PageHeading1>{t('categoryPage:heading1')}</PageHeading1>
      <PageHeading2>{categoryName}</PageHeading2>
      <StyledSearchContainer>
        <Search
          validationSchema={validationSchema}
          items={searchSuggestions}
          $showButton
          onSubmit={handleSubmit}
        />
      </StyledSearchContainer>
      <SubCategoriesContainer>{renderSubCategories()}</SubCategoriesContainer>
    </DefaultLayout>
  )
}

export default CategoryPage
