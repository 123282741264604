import {useField} from 'formik'
import {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import CheckboxField from '../CheckboxField'
import TimeField from '../TimeField'
import {
  StyledCheckboxFields,
  StyledDay,
  StyledDayWrapper,
  StyledTimeFields,
} from './styles'

export default function OperatingHoursRow({day}) {
  const {t} = useTranslation()
  const [isOpenedAllDayField] = useField(`${day}AllDay`)
  const [isClosedField] = useField(`${day}IsClosed`)
  const [isOpened247Field] = useField('isOpened247')

  const disabledTimeFields = useCallback(
    () =>
      isOpenedAllDayField.value ||
      isClosedField.value ||
      isOpened247Field.value,
    [isOpenedAllDayField.value, isClosedField.value, isOpened247Field.value],
  )

  const disabledAllDay = useCallback(
    () => isClosedField.value || isOpened247Field.value,
    [isClosedField.value, isOpened247Field.value],
  )

  const disabledIsClosed = useCallback(
    () => isOpened247Field.value,
    [isOpened247Field.value],
  )

  return (
    <StyledDayWrapper>
      <StyledDay $disabled={disabledIsClosed()}>
        {t(`common:days.${day}.long`)}:
      </StyledDay>
      <StyledTimeFields>
        <TimeField
          name={`${day}StartTime`}
          day={day}
          disabled={disabledTimeFields()}
        />
        <TimeField
          name={`${day}EndTime`}
          day={day}
          disabled={disabledTimeFields()}
        />
      </StyledTimeFields>
      <StyledCheckboxFields>
        <CheckboxField
          id={`${day}AllDay`}
          name={`${day}AllDay`}
          label={t('registrationPage:step2.form.operatingHours.isOpenedAllDay')}
          disabled={disabledAllDay()}
        />
        <CheckboxField
          id={`${day}IsClosed`}
          name={`${day}IsClosed`}
          label={t('registrationPage:step2.form.operatingHours.isClosed')}
          disabled={disabledIsClosed()}
        />
      </StyledCheckboxFields>
    </StyledDayWrapper>
  )
}
