import {useField, useFormikContext} from 'formik'
import {useCallback, useEffect, useState} from 'react'
import ImagePicker from '../../../../../../components/ImagePicker'
import {StyledFieldError} from '../../../../../../styles/common'
import {MAX_IMAGE_COMPRESSED_SIZE_IN_MB} from '../../../../../../utils/constants'
import {
  blobToBase64,
  setCustomFormikFieldValue,
  uuid,
} from '../../../../../../utils/functions'
import {StyledPickersWrapper} from './styles'

export default function ImagePickersField({
  name,
  max = 1,
  aspectRatio,
  pickerWidth = '128px',
  maxImageSizeInMB = MAX_IMAGE_COMPRESSED_SIZE_IN_MB,
}) {
  const [base64Images, setBase64Images] = useState([])
  const [field, meta] = useField(name)
  const {setFieldValue, setFieldTouched} = useFormikContext()

  const multiplePickers = useCallback(
    () => Array.isArray(field.value),
    [field.value],
  )

  useEffect(() => {
    let blobs = multiplePickers() ? field.value : [field.value]

    blobs = blobs.filter(Boolean)

    const promises = blobs.map(blobToBase64)

    Promise.all(promises).then(base64Values => {
      const indexedBase64Values = base64Values.map((base64, index) => ({
        id: field.value[index]?.id || uuid(),
        base64,
      }))

      setBase64Images(indexedBase64Values)
    })
  }, [field.value, multiplePickers])

  const error = useCallback(
    () => meta.touched && meta.error,
    [meta.error, meta.touched],
  )

  const renderPickers = useCallback(() => {
    let morePickers = true

    if (multiplePickers()) {
      if (field.value.length >= max) {
        morePickers = false
      }
    } else if (field.value) {
      morePickers = false
    }

    const handleBrowse = async ({base64, index, replace = true}) => {
      const blob = await fetch(base64).then(res => res?.blob?.())
      let newBlobs = blob

      if (multiplePickers()) {
        if (replace) {
          newBlobs = field.value.map((fieldBlob, i) =>
            i === index ? blob : fieldBlob,
          )
        } else {
          newBlobs = [...field.value, blob]
        }
      }

      setCustomFormikFieldValue({
        name,
        value: newBlobs,
        setFieldValue,
        setFieldTouched,
      })
    }

    const handleClear = index => {
      let newValue = null

      if (multiplePickers()) {
        newValue = field.value.filter((_blob, i) => i !== index)
      }

      setCustomFormikFieldValue({
        name,
        value: newValue,
        setFieldValue,
        setFieldTouched,
      })
    }

    return (
      <>
        {base64Images.map(({base64, id}, index) => (
          <ImagePicker
            key={`image-picker-${name}-${id}`}
            id={`image-picker-${name}-${id}`}
            width={pickerWidth}
            aspectRatio={aspectRatio}
            maxImageSizeInMB={maxImageSizeInMB}
            handleBrowse={base64Val => handleBrowse({base64: base64Val, index})}
            handleClear={() => handleClear(index)}
            defaultSelectedImage={base64}
          />
        ))}
        {morePickers && (
          <ImagePicker
            id={`image-picker-${name}-placeholder`}
            width={pickerWidth}
            aspectRatio={aspectRatio}
            maxImageSizeInMB={maxImageSizeInMB}
            handleBrowse={base64 => handleBrowse({base64, replace: false})}
            placeholder
          />
        )}
      </>
    )
  }, [
    aspectRatio,
    base64Images,
    field.value,
    max,
    maxImageSizeInMB,
    multiplePickers,
    name,
    pickerWidth,
    setFieldTouched,
    setFieldValue,
  ])

  const renderError = useCallback(
    () => !!error() && <StyledFieldError>{error()}</StyledFieldError>,
    [error],
  )

  return (
    <>
      <StyledPickersWrapper>{renderPickers()}</StyledPickersWrapper>
      {renderError()}
    </>
  )
}
