import {useCallback, useEffect} from 'react'
import {useField, useFormikContext} from 'formik'
import {useTranslation} from 'react-i18next'
import {toast} from 'react-toastify'
import {useTheme} from 'styled-components'
import Map from '../../../../../../components/Map'
import Marker from '../../../../../../components/Map/Marker'
import {StyledMapHeader, StyledMapWrapper} from './styles'
import Clickable, {Button} from '../../../../../../components/Clickable'
import {MAX_GEO_POS_ERROR_CODE} from '../../../../../../utils/constants'
import Icon from '../../../../../../components/Icon'
import {
  getCurrentPosition,
  setCustomFormikFieldValue,
} from '../../../../../../utils/functions'

export default function MapField({name, markerNote, geolocationNote}) {
  const {t} = useTranslation()
  const {setFieldValue, setFieldTouched, status, setStatus} = useFormikContext()
  const [field] = useField(name)
  const theme = useTheme()

  const fetchCurrentPosition = useCallback(() => {
    const onSuccess = coords => {
      setCustomFormikFieldValue({
        name,
        value: coords,
        setFieldValue,
        setFieldTouched,
      })
    }

    const onError = e => {
      console.log('e :>> ', e)

      const errorCode = e?.code
      let errorLabelKey = ''

      if (!e) {
        errorLabelKey = 'unsupported'
      } else if (errorCode > MAX_GEO_POS_ERROR_CODE) {
        errorLabelKey = 'unknown'
      } else {
        errorLabelKey = errorCode
      }

      toast.error(t(`map:errorByCode.${errorLabelKey}`, {code: e?.code}))
    }

    getCurrentPosition({
      initialLocationFetched: status?.initialLocationFetched,
      onSuccess,
      onError,
    })

    if (!status?.initialLocationFetched) {
      setStatus({initialLocationFetched: true})
    }
  }, [
    name,
    setFieldTouched,
    setFieldValue,
    setStatus,
    status?.initialLocationFetched,
    t,
  ])

  useEffect(() => {
    if (!status?.initialLocationFetched) {
      fetchCurrentPosition()
    }
  }, [fetchCurrentPosition, setStatus, status?.initialLocationFetched])

  const onMapMarkerDragEnd = useCallback(
    ({latLng}) => {
      const newLat = latLng.lat()
      const newLng = latLng.lng()
      const newLatLng = {lat: newLat, lng: newLng}

      setCustomFormikFieldValue({
        name,
        value: newLatLng,
        setFieldValue,
        setFieldTouched,
      })
    },
    [name, setFieldTouched, setFieldValue],
  )

  const position = useCallback(
    () => ({lat: field.value?.lat, lng: field.value?.lng}),
    [field.value?.lat, field.value?.lng],
  )

  return (
    <StyledMapWrapper>
      <p className="note">{markerNote}</p>
      <StyledMapHeader>
        <p>{geolocationNote}</p>
        <Clickable
          as={Button}
          onClick={fetchCurrentPosition}
          $variant="danger"
          $rounded
        >
          <Icon name="locate" width="1.25rem" color={theme.colors.white} />
        </Clickable>
      </StyledMapHeader>
      <Map center={position()} zoom={15} width="100%" height="300px">
        <Marker
          position={position()}
          content={t('registrationPage:step2.form.address.map.title')}
          onDragEnd={onMapMarkerDragEnd}
          draggable
        />
      </Map>
    </StyledMapWrapper>
  )
}
