import styled from 'styled-components'
import Clickable from '../../../components/Clickable'
import {categoryCardSize} from '../../../styles/common'

export const CategoriesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(${categoryCardSize}, max-content)
  );
  grid-gap: 1.25rem 0.75rem;
  width: 100%;
  max-width: 100%;
  justify-content: center;
  margin: 2rem 0 0 0;
`

export const StyledLink = styled(Clickable)`
  text-decoration: none;

  & > div {
    transition: box-shadow 0.2s ease-in-out;

    :hover {
      box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
    }
  }
`
