import styled from 'styled-components'
import {formInputStyles} from '../../../../../../../styles/common'

export const StyledPhoneFieldWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  padding-left: 2px;

  & > .PhoneInput {
    & > input.PhoneInputInput {
      ${formInputStyles}
    }
  }
`
