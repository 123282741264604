import {useCallback, useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Formik} from 'formik'
import {array, number, object, string, mixed, boolean} from 'yup'
import {isPossiblePhoneNumber} from 'react-phone-number-input'
import isAfter from 'date-fns/isAfter'
import parse from 'date-fns/parse'
import {toast} from 'react-toastify'
import {useNavigate} from 'react-router-dom'
import countries from 'i18n-iso-countries'
import enCountryList from 'i18n-iso-countries/langs/en.json'
import frCountryList from 'i18n-iso-countries/langs/fr.json'
import DefaultLayout from '../../layouts/DefaultLayout'
import Icon from '../../components/Icon'
import {
  MainContentWrapper,
  PageHeadingWrapper,
  StepDescription,
  StyledForm,
  StyledGreetings,
  StyledLoaderWrapper,
} from './styles'
import {PageHeading1, StyledLoader} from '../../styles/common'
import Step1 from './components/Step1'
import Step2 from './components/Step2'
import Step3 from './components/Step3'
import Step4 from './components/Step4'
import Footer from './components/Footer'
import {
  getCategoriesAndSubCategories,
  getPackages,
  registerCompany,
} from '../../services/api'
import {
  DEFAULT_COUNTRY_CODE,
  ORION_ADDRESS_LAT,
  ORION_ADDRESS_LNG,
  TOTAL_REGISTRATION_STEPS,
} from '../../utils/constants'

countries.registerLocale(enCountryList)
countries.registerLocale(frCountryList)

const MAX_STR_LENGTH = 160

export default function RegistrationPage() {
  const {t, i18n} = useTranslation()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const [currentStep, setCurrentStep] = useState(1)
  const [packages, setPackages] = useState([])
  const [nestedSubCategories, setNestedSubCategories] = useState([])
  const formikRef = useRef(null)

  useEffect(() => {
    let newPackages = []
    let newNestedSubCategories = []
    let setState = () => {
      setPackages(newPackages)
      setNestedSubCategories(newNestedSubCategories)
      setIsLoading(false)
    }

    Promise.all([
      getPackages().then(res => {
        newPackages = res.map(pkg => ({
          _id: pkg?._id || '',
          name: pkg?.label || '',
          price: pkg?.price?.value || 0,
          currency: pkg?.price?.currency?.label || 'MUR',
          description: pkg?.descriptions || '',
          color: pkg?.color || '#27D0C8',
          maxSubCategories: pkg?.include?.maxKeywords || 1,
          maxPhones: pkg?.include?.maxPhonenumbers || 0,
          email: pkg?.include?.email || false,
          socialLinks: pkg?.include?.socialLinks || false,
          logo: pkg?.include?.logo || false,
          maxImages: pkg?.include?.maxImages || 1,
          map: pkg?.include?.location || false,
          maxImageSizeInMB: pkg?.include?.maxImageSizeInMB ?? 2,
          previewUrl: pkg?.preview_image || '',
        }))
      }),
      getCategoriesAndSubCategories().then(res => {
        newNestedSubCategories = res.map(categoryData => {
          const categoryOpts = categoryData.subCategories.map(subCat => ({
            label: subCat?.label,
            value: subCat?.id,
          }))
          const newCategoryObj = {
            label: categoryData.label,
            options: categoryOpts,
          }

          return newCategoryObj
        })
      }),
    ])
      .then(() => setState?.())
      .catch(e => {
        console.log('e :>> ', e)
        toast.error(t('common:errors.unknownRefresh'))
        setIsLoading(false)
      })

    return () => {
      setState = null
    }
  }, [setIsLoading, t])

  const selectedPackage = useCallback(
    formikValues => {
      const resultingValues = formikValues || formikRef.current?.values

      const anyPkgIsSelected = packages.find(
        pkg => pkg._id === resultingValues?.packageId,
      )

      return anyPkgIsSelected
    },
    [packages],
  )

  const initialValues = useCallback(
    () => ({
      packageId: '',
      name: '',
      description: '',
      streetAddress: '',
      townVillage: null,
      country: {
        value: DEFAULT_COUNTRY_CODE,
        label: countries.getName(DEFAULT_COUNTRY_CODE, i18n.language),
      },
      latLng: {
        lat: ORION_ADDRESS_LAT,
        lng: ORION_ADDRESS_LNG,
      },
      subCategories: [],
      phoneNumber1: '',
      phoneNumber2: '',
      email: '',
      mondayStartTime: '08:00',
      mondayEndTime: '17:00',
      mondayAllDay: false,
      mondayIsClosed: false,
      tuesdayStartTime: '08:00',
      tuesdayEndTime: '17:00',
      tuesdayAllDay: false,
      tuesdayIsClosed: false,
      wednesdayStartTime: '08:00',
      wednesdayEndTime: '17:00',
      wednesdayAllDay: false,
      wednesdayIsClosed: false,
      thursdayStartTime: '08:00',
      thursdayEndTime: '17:00',
      thursdayAllDay: false,
      thursdayIsClosed: false,
      fridayStartTime: '08:00',
      fridayEndTime: '17:00',
      fridayAllDay: false,
      fridayIsClosed: false,
      saturdayStartTime: '08:00',
      saturdayEndTime: '17:00',
      saturdayAllDay: false,
      saturdayIsClosed: false,
      sundayStartTime: '08:00',
      sundayEndTime: '17:00',
      sundayAllDay: false,
      sundayIsClosed: false,
      isOpened247: false,
      isOpenedOnPublicHolidays: false,
      instagram: '',
      twitter: '',
      linkedIn: '',
      facebook: '',
      whatsApp: '',
      logo: null,
      images: [],
      readTermsAndConditions: false,
    }),
    [i18n.language],
  )

  const validationSchema = useCallback(() => {
    let schema = {}

    if (currentStep === 1) {
      schema = {
        packageId: string()
          .nullable()
          .trim()
          .required(t('common:errors.required')),
      }
    }

    if (currentStep === 2) {
      schema = {
        name: string()
          .nullable()
          .trim()
          .required(t('common:errors.required'))
          .max(
            MAX_STR_LENGTH,
            t('common:errors.maxChars', {length: MAX_STR_LENGTH}),
          ),
        description: string()
          .nullable()
          .trim()
          .required(t('common:errors.required'))
          .max(
            MAX_STR_LENGTH,
            t('common:errors.maxChars', {length: MAX_STR_LENGTH}),
          ),
        streetAddress: string()
          .nullable()
          .trim()
          .optional()
          .max(
            MAX_STR_LENGTH,
            t('common:errors.maxChars', {length: MAX_STR_LENGTH}),
          ),
        townVillage: object()
          .shape({
            value: string(),
            label: string(),
          })
          .nullable()
          .required(t('common:errors.required')),
        country: object()
          .shape({
            value: string(),
            label: string(),
          })
          .nullable()
          .required(t('common:errors.required')),
        subCategories: array()
          .of(
            object().shape({
              value: string(),
              label: string(),
            }),
          )
          .max(
            selectedPackage().maxSubCategories,
            t('common:errors.maxItems', {
              count: selectedPackage().maxSubCategories,
            }),
          )
          .min(1, t('common:errors.required')),
        mondayEndTime: string()
          .nullable()
          .test(
            'required',
            t('common:errors.laterTimeThan', {
              from: t(
                'registrationPage:step2.form.operatingHours.laterThanFrom',
              ),
            }),
            function (value) {
              const {
                mondayStartTime,
                mondayAllDay,
                mondayIsClosed,
                isOpened247,
                // eslint-disable-next-line react/no-this-in-sfc
              } = this.parent
              const performValidation = !(
                mondayAllDay ||
                mondayIsClosed ||
                isOpened247
              )
              const start = parse(mondayStartTime, 'HH:mm', new Date())
              const end = parse(value, 'HH:mm', new Date())
              const validity = performValidation ? isAfter(end, start) : true

              return validity
            },
          ),
        tuesdayEndTime: string()
          .nullable()
          .test(
            'required',
            t('common:errors.laterTimeThan', {
              from: t(
                'registrationPage:step2.form.operatingHours.laterThanFrom',
              ),
            }),
            function (value) {
              const {
                tuesdayStartTime,
                tuesdayAllDay,
                tuesdayIsClosed,
                isOpened247,
                // eslint-disable-next-line react/no-this-in-sfc
              } = this.parent
              const performValidation = !(
                tuesdayAllDay ||
                tuesdayIsClosed ||
                isOpened247
              )
              const start = parse(tuesdayStartTime, 'HH:mm', new Date())
              const end = parse(value, 'HH:mm', new Date())
              const validity = performValidation ? isAfter(end, start) : true

              return validity
            },
          ),
        wednesdayEndTime: string()
          .nullable()
          .test(
            'required',
            t('common:errors.laterTimeThan', {
              from: t(
                'registrationPage:step2.form.operatingHours.laterThanFrom',
              ),
            }),
            function (value) {
              const {
                wednesdayStartTime,
                wednesdayAllDay,
                wednesdayIsClosed,
                isOpened247,
              } =
                // eslint-disable-next-line react/no-this-in-sfc
                this.parent
              const performValidation = !(
                wednesdayAllDay ||
                wednesdayIsClosed ||
                isOpened247
              )
              const start = parse(wednesdayStartTime, 'HH:mm', new Date())
              const end = parse(value, 'HH:mm', new Date())
              const validity = performValidation ? isAfter(end, start) : true

              return validity
            },
          ),
        thursdayEndTime: string()
          .nullable()
          .test(
            'required',
            t('common:errors.laterTimeThan', {
              from: t(
                'registrationPage:step2.form.operatingHours.laterThanFrom',
              ),
            }),
            function (value) {
              const {
                thursdayStartTime,
                thursdayAllDay,
                thursdayIsClosed,
                isOpened247,
              } =
                // eslint-disable-next-line react/no-this-in-sfc
                this.parent
              const performValidation = !(
                thursdayAllDay ||
                thursdayIsClosed ||
                isOpened247
              )
              const start = parse(thursdayStartTime, 'HH:mm', new Date())
              const end = parse(value, 'HH:mm', new Date())
              const validity = performValidation ? isAfter(end, start) : true

              return validity
            },
          ),
        fridayEndTime: string()
          .nullable()
          .test(
            'required',
            t('common:errors.laterTimeThan', {
              from: t(
                'registrationPage:step2.form.operatingHours.laterThanFrom',
              ),
            }),
            function (value) {
              const {
                fridayStartTime,
                fridayAllDay,
                fridayIsClosed,
                isOpened247,
                // eslint-disable-next-line react/no-this-in-sfc
              } = this.parent
              const performValidation = !(
                fridayAllDay ||
                fridayIsClosed ||
                isOpened247
              )
              const start = parse(fridayStartTime, 'HH:mm', new Date())
              const end = parse(value, 'HH:mm', new Date())
              const validity = performValidation ? isAfter(end, start) : true

              return validity
            },
          ),
        saturdayEndTime: string()
          .nullable()
          .test(
            'required',
            t('common:errors.laterTimeThan', {
              from: t(
                'registrationPage:step2.form.operatingHours.laterThanFrom',
              ),
            }),
            function (value) {
              const {
                saturdayStartTime,
                saturdayAllDay,
                saturdayIsClosed,
                isOpened247,
              } =
                // eslint-disable-next-line react/no-this-in-sfc
                this.parent
              const performValidation = !(
                saturdayAllDay ||
                saturdayIsClosed ||
                isOpened247
              )
              const start = parse(saturdayStartTime, 'HH:mm', new Date())
              const end = parse(value, 'HH:mm', new Date())
              const validity = performValidation ? isAfter(end, start) : true

              return validity
            },
          ),
        sundayEndTime: string()
          .nullable()
          .test(
            'required',
            t('common:errors.laterTimeThan', {
              from: t(
                'registrationPage:step2.form.operatingHours.laterThanFrom',
              ),
            }),
            function (value) {
              const {
                sundayStartTime,
                sundayAllDay,
                sundayIsClosed,
                isOpened247,
                // eslint-disable-next-line react/no-this-in-sfc
              } = this.parent
              const performValidation = !(
                sundayAllDay ||
                sundayIsClosed ||
                isOpened247
              )
              const start = parse(sundayStartTime, 'HH:mm', new Date())
              const end = parse(value, 'HH:mm', new Date())
              const validity = performValidation ? isAfter(end, start) : true

              return validity
            },
          ),
      }

      if (selectedPackage()?.map) {
        schema.latLng = object().shape({
          lat: number().nullable().required(t('common:errors.required')),
          lng: number().nullable().required(t('common:errors.required')),
        })
      }

      if (selectedPackage()?.maxPhones) {
        if (selectedPackage().maxPhones > 0) {
          schema.phoneNumber1 = string()
            .nullable()
            .trim()
            .required(t('common:errors.required'))
            .max(
              MAX_STR_LENGTH,
              t('common:errors.maxChars', {length: MAX_STR_LENGTH}),
            )
            .test(
              'phoneNumberValidation',
              t('common:errors.phone'),
              val => !!val && isPossiblePhoneNumber(val),
            )
        }

        if (selectedPackage().maxPhones > 1) {
          schema.phoneNumber2 = string()
            .nullable()
            .optional()
            .max(
              MAX_STR_LENGTH,
              t('common:errors.maxChars', {length: MAX_STR_LENGTH}),
            )
            .test(
              'phoneNumberValidation',
              t('common:errors.phone'),
              val => !val || isPossiblePhoneNumber(val),
            )
        }
      }

      if (selectedPackage()?.email) {
        schema.email = string()
          .nullable()
          .optional()
          .email(t('common:errors.email'))
          .max(
            MAX_STR_LENGTH,
            t('common:errors.maxChars', {length: MAX_STR_LENGTH}),
          )
      }

      if (selectedPackage()?.socialLinks) {
        schema.instagram = string()
          .nullable()
          .url(t('common:errors.url'))
          .matches(
            /^(https?:\/\/){0,1}(www\.){0,1}instagram\.com/,
            t('common:errors.urlWithField', {field: 'Instagram'}),
          )
          .optional()
          .max(
            MAX_STR_LENGTH,
            t('common:errors.maxChars', {length: MAX_STR_LENGTH}),
          )
        schema.twitter = string()
          .nullable()
          .url(t('common:errors.url'))
          .matches(
            /^(https?:\/\/){0,1}(www\.){0,1}twitter\.com/,
            t('common:errors.urlWithField', {field: 'Twitter'}),
          )
          .optional()
          .max(
            MAX_STR_LENGTH,
            t('common:errors.maxChars', {length: MAX_STR_LENGTH}),
          )
        schema.linkedIn = string()
          .nullable()
          .url(t('common:errors.url'))
          .matches(
            /^(https?:\/\/){0,1}(www\.){0,1}linkedin\.com/,
            t('common:errors.urlWithField', {field: 'LinkedIn'}),
          )
          .optional()
          .max(
            MAX_STR_LENGTH,
            t('common:errors.maxChars', {length: MAX_STR_LENGTH}),
          )
        schema.facebook = string()
          .nullable()
          .url(t('common:errors.url'))
          .matches(
            /^(https?:\/\/){0,1}(www\.){0,1}facebook\.com/,
            t('common:errors.urlWithField', {field: 'Facebook'}),
          )
          .optional()
          .max(
            MAX_STR_LENGTH,
            t('common:errors.maxChars', {length: MAX_STR_LENGTH}),
          )
        schema.whatsApp = string()
          .nullable()
          .optional()
          .max(
            MAX_STR_LENGTH,
            t('common:errors.maxChars', {length: MAX_STR_LENGTH}),
          )
          .test(
            'phoneNumberValidation',
            t('common:errors.phone'),
            val => !val || isPossiblePhoneNumber(val),
          )
      }
    }

    if (currentStep === 3) {
      schema = {
        images: array().min(1, t('common:errors.atleastOneImage')),
        readTermsAndConditions: boolean().isTrue(),
      }

      if (selectedPackage()?.logo) {
        schema.logo = mixed()
      }
    }

    return object(schema)
  }, [currentStep, selectedPackage, t])

  const handleSubmit = useCallback(
    async (values, {setSubmitting, setTouched}) => {
      const shouldRegisterCompany = currentStep === TOTAL_REGISTRATION_STEPS - 1
      const isLastStep = currentStep === TOTAL_REGISTRATION_STEPS

      if (shouldRegisterCompany) {
        await registerCompany(values)
          .then(() => {
            setCurrentStep(p => p + 1)
          })
          .catch(e => {
            console.log('e :>> ', e)
            toast.error(e?.message || t('common:errors.unknown'))
          })
      } else if (isLastStep) {
        navigate('/')
      } else {
        setCurrentStep(p => p + 1)

        const fields = Object.keys(validationSchema().fields)
        const untouchedFields = fields.reduce(
          ({field, prevObj}) => ({...prevObj, [field]: false}),
          {},
        )

        setTouched(untouchedFields)
      }

      setSubmitting(false)
    },
    [currentStep, navigate, t, validationSchema],
  )

  const currentPkgPreviewUrl = useCallback(
    formikValues => selectedPackage(formikValues)?.previewUrl,
    [selectedPackage],
  )

  const renderGreetings = useCallback(
    () => (
      <StyledGreetings>
        <Icon name="theFutur" size="48px" />
        <p className="greetings__title">
          {t(`registrationPage:step${currentStep}.title`)}
        </p>
      </StyledGreetings>
    ),
    [currentStep, t],
  )

  const renderStepDescription = useCallback(
    () => (
      <StepDescription>
        {t(`registrationPage:step${currentStep}.step`)}:{' '}
        {t(`registrationPage:step${currentStep}.description`)}
      </StepDescription>
    ),
    [currentStep, t],
  )

  const renderStep = useCallback(() => {
    if (currentStep === 1)
      return <Step1 packages={packages} isLoading={isLoading} />

    if (currentStep === 2) {
      return (
        <Step2
          maxSubCategories={selectedPackage()?.maxSubCategories}
          maxPhones={selectedPackage()?.maxPhones}
          email={selectedPackage()?.email}
          socialLinks={selectedPackage()?.socialLinks}
          map={selectedPackage()?.map}
          nestedSubCategories={nestedSubCategories}
        />
      )
    }

    if (currentStep === 3)
      return (
        <Step3
          logoRequired={selectedPackage()?.logo}
          maxImages={selectedPackage()?.maxImages}
          maxImageSizeInMB={selectedPackage()?.maxImageSizeInMB}
        />
      )

    if (currentStep === 4)
      return (
        <Step4
          price={selectedPackage()?.price}
          currency={selectedPackage()?.currency}
        />
      )

    return null
  }, [currentStep, isLoading, nestedSubCategories, packages, selectedPackage])

  const renderForm = useCallback(
    ({values, isSubmitting}) => (
      <StyledForm>
        <MainContentWrapper>
          {renderGreetings()}
          <PageHeadingWrapper>
            <PageHeading1>{t('registrationPage:title')}</PageHeading1>
          </PageHeadingWrapper>
          {renderStepDescription()}
          {isSubmitting ? (
            <StyledLoaderWrapper>
              <StyledLoader $size="32px" />
            </StyledLoaderWrapper>
          ) : (
            renderStep()
          )}
        </MainContentWrapper>
        <Footer
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          previewUrl={currentPkgPreviewUrl(values)}
        />
      </StyledForm>
    ),
    [
      currentPkgPreviewUrl,
      currentStep,
      renderGreetings,
      renderStep,
      renderStepDescription,
      t,
    ],
  )

  return (
    <DefaultLayout loading={isLoading} noBottomPadding>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues()}
        validationSchema={validationSchema()}
        onSubmit={handleSubmit}
      >
        {renderForm}
      </Formik>
    </DefaultLayout>
  )
}
