import styled from 'styled-components'
import {formInputStyles} from '../../../styles/common'

export const StyledWrapped = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 100%;
`

export const StyledLabel = styled.label`
  border-width: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
`

export const StyledInput = styled.input`
  ${formInputStyles}
`
