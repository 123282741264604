import {StyledSvg} from './styles'

function WhatsAppIcon({size, color, width, height, ...props}) {
  return (
    <StyledSvg
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      $size={size}
      $width={width}
      $height={height}
      {...props}
    >
      <path
        d="M.976 40.894 3.8 30.623a19.716 19.716 0 0 1-2.658-9.91C1.147 9.786 10.08.894 21.059.894c5.328.003 10.328 2.067 14.088 5.814 3.76 3.746 5.83 8.726 5.829 14.023-.005 10.928-8.94 19.82-19.917 19.82h-.008a19.971 19.971 0 0 1-9.517-2.412L.976 40.894Zm11.047-6.344.604.356a16.588 16.588 0 0 0 8.426 2.297h.006c9.124 0 16.55-7.391 16.554-16.475a16.336 16.336 0 0 0-4.844-11.655A16.484 16.484 0 0 0 21.065 4.24c-9.13 0-16.556 7.39-16.56 16.474a16.375 16.375 0 0 0 2.531 8.767l.394.624-1.673 6.079 6.266-1.636Z"
        fill="#fff"
      />
      <path
        d="m1.668 40.202 2.728-9.916A19.032 19.032 0 0 1 1.83 20.72c.004-10.55 8.629-19.134 19.226-19.134 5.144.003 9.971 1.995 13.6 5.612 3.63 3.617 5.629 8.424 5.627 13.537-.005 10.55-8.63 19.135-19.226 19.135-.001 0 0 0 0 0h-.009a19.294 19.294 0 0 1-9.187-2.329l-10.193 2.66Z"
        fill={color || '#64B161'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.082 12.428c-.373-.825-.766-.842-1.12-.856-.29-.013-.623-.012-.954-.012-.332 0-.87.124-1.327.62-.456.496-1.742 1.694-1.742 4.132 0 2.437 1.783 4.793 2.032 5.123.249.33 3.443 5.493 8.501 7.478 4.204 1.65 5.059 1.322 5.971 1.24.913-.083 2.945-1.198 3.36-2.355.414-1.157.414-2.148.29-2.356-.125-.206-.456-.33-.954-.578-.498-.247-2.944-1.446-3.4-1.611-.457-.166-.788-.248-1.12.248-.332.495-1.285 1.611-1.576 1.941-.29.331-.58.373-1.078.125-.498-.248-2.1-.771-4.002-2.459-1.479-1.313-2.478-2.935-2.768-3.43-.29-.496-.005-.74.218-1.011.403-.488 1.078-1.364 1.244-1.694.166-.33.083-.62-.041-.868-.125-.248-1.092-2.697-1.534-3.677Z"
        fill="#fff"
      />
    </StyledSvg>
  )
}

export default WhatsAppIcon
