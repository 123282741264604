import {Field} from 'formik'
import styled from 'styled-components'

export const StyledCheckboxField = styled(Field)`
  border: 1px solid
    ${({theme, $error}) => theme.colors[$error ? 'red0' : 'lunarGray40']};
`

export const StyledCheckboxLabel = styled.label`
  font-size: 14px;
  color: ${({theme, $disabled}) =>
    theme.colors[$disabled ? 'lunarGray30' : 'black']};
`
