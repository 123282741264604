import {StyledSvg} from './styles'

function SearchIcon({size, color, width, height, ...props}) {
  return (
    <StyledSvg
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      $size={size}
      $width={width}
      $height={height}
      {...props}
    >
      <path
        d="m19 19-4.35-4.35M17 9A8 8 0 1 1 1 9a8 8 0 0 1 16 0Z"
        stroke={color || '#000'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSvg>
  )
}

export default SearchIcon
