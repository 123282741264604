import {useCallback, useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import Modal from 'react-modal'
import {useTheme} from 'styled-components'
import Clickable, {Button} from '../../../../components/Clickable'
import {StyledFieldSet, StyledLegendWrapper} from '../../styles'
import CheckboxField from '../Step2/components/CheckboxField'
import ImagePickersField from './components/ImagePickersField'
import {StyledTermsAndConditions, StyledWrapper} from './styles'
import {
  StyledLvl0List,
  StyledLvl0ListItem,
  StyledLvl1List,
  StyledLvl1ListItem,
  StyledLvl2List,
  StyledLvl2ListItem,
} from '../../../TermsAndConditionsTenantPage/styles'

export default function Step3({logoRequired, maxImages, maxImageSizeInMB}) {
  const {t} = useTranslation()
  const [showLegalModal, setShowLegalModal] = useState(false)
  const theme = useTheme()

  const modalStyle = useCallback(
    () => ({
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        maxHeight: '80vh',
        width: '480px',
        maxWidth: '90vw',
      },
      overlay: {
        zIndex: 9,
        backgroundColor: `${theme.colors.white}ee`,
      },
    }),
    [theme.colors.white],
  )

  const toggleModal = useCallback(
    () => setShowLegalModal(prevVal => !prevVal),
    [setShowLegalModal],
  )

  const renderLogoFieldSet = useCallback(
    () =>
      !!logoRequired && (
        <StyledFieldSet>
          <StyledLegendWrapper>
            <legend>{t('registrationPage:step3.form.logo.label')}</legend>
          </StyledLegendWrapper>
          <ImagePickersField
            name="logo"
            maxImageSizeInMB={maxImageSizeInMB}
            aspectRatio="1 / 1"
          />
        </StyledFieldSet>
      ),
    [logoRequired, maxImageSizeInMB, t],
  )

  return (
    <StyledWrapper>
      <Modal
        isOpen={showLegalModal}
        onRequestClose={toggleModal}
        style={modalStyle()}
        shouldCloseOnOverlayClick
      >
        <Trans
          components={{
            'lvl-0-list': <StyledLvl0List />,
            'lvl-0-list-item': <StyledLvl0ListItem />,
            'lvl-1-list': <StyledLvl1List />,
            'lvl-1-list-item': <StyledLvl1ListItem />,
            'lvl-2-list': <StyledLvl2List />,
            'lvl-2-list-item': <StyledLvl2ListItem />,
            bold: <b />,
            underline: <u />,
          }}
        >
          {t('termsAndConditionsTenantPage:content')}
        </Trans>
      </Modal>
      {renderLogoFieldSet()}
      <StyledFieldSet>
        <StyledLegendWrapper>
          <legend>{`${t(
            'registrationPage:step3.form.images.label',
          )} *`}</legend>
        </StyledLegendWrapper>
        <ImagePickersField
          name="images"
          max={maxImages}
          aspectRatio="4 / 3"
          maxImageSizeInMB={maxImageSizeInMB}
        />
      </StyledFieldSet>
      <StyledTermsAndConditions>
        <CheckboxField
          id="readTermsAndConditions"
          name="readTermsAndConditions"
          label={t('registrationPage:step3.form.readTermsAndConditions')}
        />
        <Clickable as={Button} onClick={toggleModal}>
          {t('registrationPage:step3.form.termsAndConditions')}
        </Clickable>
      </StyledTermsAndConditions>
    </StyledWrapper>
  )
}
