import styled from 'styled-components'

export const StyledFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  bottom: 0;
  padding-top: 1rem;
  padding-bottom: 2rem;
  background-color: ${({theme}) => theme.colors.white};
  width: 100%;
  box-shadow: ${({theme}) =>
    `16px 0 ${theme.colors.white}, -16px 0 ${theme.colors.white}`};

  & > div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    & > .footer-btn {
      & > .btn-text {
        flex-grow: 1;
      }
    }
  }
`

export const StyledPreviewImage = styled.img`
  width: 80vw;
  max-width: 80vw;
  max-height: 80vh;
  object-fit: contain;
  text-align: center;
`
