const colorMap = {
  success: {
    color: 'btnSuccessFg',
    backgroundColor: 'btnSuccessBg',
  },
  info: {
    color: 'btnInfoFg',
    backgroundColor: 'btnInfoBg',
  },
  sterling: {
    color: 'btnSterlingFg',
    backgroundColor: 'btnSterlingBg',
  },
  ash: {
    backgroundColor: 'btnAshBg',
    color: 'btnAshFg',
  },
  navy: {
    backgroundColor: 'btnNavyBg',
    color: 'btnNavyFg',
  },
  whiteSmoke: {
    color: 'btnWhiteSmokeFg',
    backgroundColor: 'btnWhiteSmokeBg',
  },
  danger: {
    color: 'btnDangerFg',
    backgroundColor: 'btnDangerBg',
  },
  plain: {
    color: 'btnPlainFg',
    backgroundColor: 'btnPlainBg',
  },
}

export default colorMap
