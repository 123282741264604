import {StyledSvg} from './styles'

function MarkerUnderlinedIcon({size, color, width, height, ...props}) {
  return (
    <StyledSvg
      viewBox="0 0 18 22"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      $size={size}
      $width={width}
      $height={height}
      {...props}
    >
      <path
        d="M17.46 8.63A8.5 8.5 0 0 0 4.3 2.36 8.56 8.56 0 0 0 .54 8.63 8.46 8.46 0 0 0 3 15.46l5.3 5.31a1.001 1.001 0 0 0 1.42 0L15 15.46a8.46 8.46 0 0 0 2.46-6.83Zm-3.86 5.42L9 18.65l-4.6-4.6a6.49 6.49 0 0 1-1.87-5.22A6.57 6.57 0 0 1 5.42 4a6.47 6.47 0 0 1 7.16 0 6.57 6.57 0 0 1 2.89 4.81 6.49 6.49 0 0 1-1.87 5.24ZM9 5a4.5 4.5 0 1 0 4.5 4.5A4.51 4.51 0 0 0 9 5Zm0 7a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5Z"
        fill={color || '#000'}
      />
    </StyledSvg>
  )
}

export default MarkerUnderlinedIcon
