import {useCallback, useMemo, useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {object} from 'yup'
import {useTranslation} from 'react-i18next'
import Cookies from 'js-cookie'
import Search from '../../components/Search'
import useDebounce from '../../utils/hooks/useDebounce'
import {
  getSubCategorySuggestions,
  getLocationSuggestions,
} from '../../services/api'
import Icon from '../../components/Icon'
// import Clickable from '../../components/Clickable'
import Menu from '../../components/Menu'
// import {footerItems} from './data/footerItems'
import {
  StyledContainer,
  /* StyledFooter, */ StyledLink,
  StyledMain,
} from './styles'
import Splash from './components/Splash'
import {
  HIDE_SPLASH_PAGE_PERMANENTLY,
  SKIPPED_SPLASH_PAGE,
} from '../../utils/constants'

function HomePage() {
  const {t} = useTranslation()
  const navigate = useNavigate()

  const validationSchema = useMemo(
    () =>
      object().shape(
        {
          search: object()
            .nullable()
            .when('location', (locationVal, field) =>
              locationVal ? field : field.required(),
            ),
          location: object()
            .nullable()
            .when('search', (searchVal, field) =>
              searchVal ? field : field.required(),
            ),
        },
        [['search', 'location']],
      ),
    [],
  )

  const subCategorySuggestions = useDebounce(
    inputValue =>
      new Promise((resolve, reject) => {
        getSubCategorySuggestions(inputValue)
          .then(res => {
            const sanitizedRes = res.map(item => ({
              value: item?._id,
              label: item?.label,
            }))

            resolve(sanitizedRes)
          })
          .catch(e => {
            console.log('e :>> ', e)
            reject(e)
          })
      }),
    500,
  )
  const locationSuggestions = useDebounce(
    inputValue =>
      new Promise((resolve, reject) => {
        getLocationSuggestions(inputValue)
          .then(res => {
            const sanitizedRes = res.map(item => ({
              value: item?._id,
              label: item?.label,
            }))

            resolve(sanitizedRes)
          })
          .catch(e => {
            console.log('e :>> ', e)
            reject(e)
          })
      }),
    500,
  )

  const searchFields = useMemo(
    () => [
      {
        key: 'search',
        name: 'search',
        defaultOptions: [],
        placeholder: t('homePage:search.whatPlaceholder'),
        loadOptions: subCategorySuggestions,
        $lighterBg: true,
        async: true,
        creatable: true,
      },
      {
        key: 'location',
        name: 'location',
        defaultOptions: [],
        placeholder: t('homePage:search.wherePlaceholder'),
        loadOptions: locationSuggestions,
        async: true,
        creatable: true,
      },
    ],
    [locationSuggestions, subCategorySuggestions, t],
  )

  const handleSubmit = useCallback(
    (values, {setSubmitting}) => {
      setSubmitting(false)

      navigate({
        pathname: '/offices',
        search: `?${new URLSearchParams({
          what: values.search?.label || '',
          where: values.location?.label || '',
        }).toString()}`,
      })
    },
    [navigate],
  )

  // const renderFooterItems = useCallback(
  //   () =>
  //     footerItems.map(item => (
  //       <Clickable
  //         key={item.key}
  //         as={Link}
  //         $variant="whiteSmoke"
  //         to={item.to}
  //         $rounded
  //       >
  //         {t(`homePage:footer.${item.labelKey}`)}
  //       </Clickable>
  //     )),
  //   [t],
  // )

  const [showSplash, setShowSplash] = useState(null)
  const skippedSplash = sessionStorage.getItem(SKIPPED_SPLASH_PAGE)

  useEffect(() => {
    const showSplashPage = !Cookies.get(HIDE_SPLASH_PAGE_PERMANENTLY)

    setShowSplash(showSplashPage)
  }, [setShowSplash])

  const closeSplash = useCallback(() => {
    setShowSplash(false)
  }, [])

  if (showSplash === null) {
    return null
  }

  if (
    showSplash &&
    !Cookies.get(HIDE_SPLASH_PAGE_PERMANENTLY) &&
    !skippedSplash
  ) {
    return <Splash handleClose={closeSplash} />
  }

  return (
    <StyledContainer>
      <Menu />
      <StyledMain>
        <Icon name="theFuturFull" width="10em" className="the-futur-logo" />
        <Search
          validationSchema={validationSchema}
          items={searchFields}
          onSubmit={handleSubmit}
          $showButton
          className="w-100"
        />
        <StyledLink as={Link} to="/categories">
          {t('homePage:allCategories')}
        </StyledLink>
      </StyledMain>
      {/* <StyledFooter>{renderFooterItems()}</StyledFooter> */}
    </StyledContainer>
  )
}

export default HomePage
