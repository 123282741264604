import styled from 'styled-components'

export const StyledWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 24px 0 0 0;
`

export const StyledTermsAndConditions = styled.div`
  width: 100%;
  max-width: 100%;
  margin: 1rem 0 0 0;
  display: inline;

  & > div {
    display: inline;
  }

  & > button {
    --btn-height: 1rem;
    padding: 0.5rem 0.25rem;
    font-size: 14px;
    color: ${({theme}) => theme.colors.blue10};
    text-decoration: underline;

    &:hover {
      color: ${({theme}) => theme.colors.darkBlue};
    }
  }
`
