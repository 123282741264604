import {Route, Routes} from 'react-router-dom'
import HomePage from '../../pages/HomePage'
import CategoriesPage from '../../pages/CategoriesPage'
import CategoryPage from '../../pages/CategoryPage'
// import PoliceListingPage from '../../pages/PoliceListingPage'
// import HospitalListingPage from '../../pages/HospitalListingPage'
// import FireListingPage from '../../pages/FireListingPage'
import OfficesPage from '../../pages/OfficesPage'
import RegistrationPage from '../../pages/RegistrationPage'
import OfficePage from '../../pages/OfficePage'
import PrivacyPage from '../../pages/PrivacyPage'
import TermsAndConditionsEndUserPage from '../../pages/TermsAndConditionsEndUserPage'
import TermsAndConditionsTenantPage from '../../pages/TermsAndConditionsTenantPage'
import ErrorPage from '../../pages/ErrorPage'

function AppRoutes() {
  return (
    <Routes>
      <Route index element={<HomePage />} />
      <Route path="/categories" element={<CategoriesPage />} />
      <Route path="/categories/:id" element={<CategoryPage />} />
      {/* <Route path="/fire" element={<FireListingPage />} />
      <Route path="/hospital" element={<HospitalListingPage />} />
      <Route path="/police" element={<PoliceListingPage />} /> */}
      <Route path="/offices" element={<OfficesPage />} />
      <Route path="/offices/:id" element={<OfficePage />} />
      <Route path="/registration" element={<RegistrationPage />} />
      <Route path="/privacy" element={<PrivacyPage />} />
      <Route
        path="/terms-and-conditions/end-user"
        element={<TermsAndConditionsEndUserPage />}
      />
      <Route
        path="/terms-and-conditions/tenant"
        element={<TermsAndConditionsTenantPage />}
      />
      <Route path="/*" element={<ErrorPage />} />
    </Routes>
  )
}

export default AppRoutes
