import {StyledSvg} from './styles'

function TwitterIcon({size, color, width, height, ...props}) {
  return (
    <StyledSvg
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      $size={size}
      $width={width}
      $height={height}
      {...props}
    >
      <circle cx={20} cy={20} r={20} fill={color || '#1DA1F2'} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 12.309a9.83 9.83 0 0 1-2.828.775 4.936 4.936 0 0 0 2.165-2.724 9.862 9.862 0 0 1-3.126 1.195 4.924 4.924 0 0 0-8.39 4.49 13.976 13.976 0 0 1-10.15-5.144 4.898 4.898 0 0 0-.665 2.476c0 1.707.867 3.215 2.19 4.098a4.908 4.908 0 0 1-2.231-.616v.061a4.93 4.93 0 0 0 3.95 4.83 4.949 4.949 0 0 1-2.225.084 4.93 4.93 0 0 0 4.6 3.42A9.881 9.881 0 0 1 8 27.291a13.938 13.938 0 0 0 7.547 2.212c9.057 0 14.01-7.502 14.01-14.008 0-.214-.005-.427-.015-.637A10.03 10.03 0 0 0 32 12.309Z"
        fill="#fff"
      />
    </StyledSvg>
  )
}

export default TwitterIcon
