import axios, {get, post} from 'axios'
import {blobToBase64} from '../utils/functions'

axios.defaults.baseURL = process.env.REACT_APP_API_URL

export function getSubCategorySuggestions(searchTerm) {
  return new Promise((resolve, reject) => {
    get(`company/keyword/${searchTerm.toLowerCase()}`)
      .then(res => {
        resolve(res?.data || [])
      })
      .catch(e => {
        reject(e)
      })
  })
}

export function getLocationSuggestions(searchTerm) {
  return new Promise((resolve, reject) => {
    get(`location/${searchTerm.toLowerCase()}`)
      .then(res => {
        resolve(res?.data || [])
      })
      .catch(e => {
        reject(e)
      })
  })
}

export function getCategories() {
  return new Promise((resolve, reject) => {
    get('mainCategory')
      .then(res => {
        resolve(res?.data || [])
      })
      .catch(e => {
        reject(e)
      })
  })
}

export function getSubCategoriesByCategoryId(id) {
  return new Promise((resolve, reject) => {
    get(`mainCategory/${id}`)
      .then(res => {
        resolve(res?.data || [])
      })
      .catch(e => {
        reject(e)
      })
  })
}

export function getListing(term) {
  return new Promise((resolve, reject) => {
    get(`applicationGateway/emergency/${term}`)
      .then(res => {
        resolve(res?.data || [])
      })
      .catch(e => {
        reject(e)
      })
  })
}
export function getEmergencyLine(countryCode, description) {
  return new Promise((resolve, reject) => {
    get(`applicationGateway/emergency/@${countryCode}`)
      .then(res => {
        const emergencyLines = res?.data?.lines || []
        const emergencyLine = emergencyLines.find(
          line => line.description === description,
        )
        const emergencyNums = emergencyLine?.phone || []
        resolve(emergencyNums)
      })
      .catch(e => {
        reject(e)
      })
  })
}

export function getOffices(what, where) {
  return new Promise((resolve, reject) => {
    const keyword = what
    const location = where

    let endpoint = `company/search/keyword/${keyword}/location/${location}`
    if (keyword && location) {
      endpoint = `company/search/keyword/${keyword}/location/${location}`
    } else if (keyword) {
      endpoint = `company/search/keyword/${keyword}`
    } else if (location) {
      endpoint = `company/search/location/${location}`
    }

    get(endpoint)
      .then(res => {
        resolve(res?.data || [])
      })
      .catch(e => {
        reject(e)
      })
  })
}

export function getOfficeById(id) {
  return new Promise((resolve, reject) => {
    get(`company/${id}`)
      .then(res => {
        resolve(res?.data || null)
      })
      .catch(e => {
        reject(e)
      })
  })
}

export function getPackages() {
  return new Promise((resolve, reject) => {
    get(`package`)
      .then(res => {
        resolve(res?.data || [])
      })
      .catch(e => {
        reject(e)
      })
  })
}

export function getCategoriesAndSubCategories() {
  return new Promise((resolve, reject) => {
    get(`mainCategory`)
      .then(res => {
        resolve(res?.data || [])
      })
      .catch(e => {
        reject(e)
      })
  })
}

async function getPackageById(id) {
  const {data: packageData} = await get(`package/id/${id}`)
  return packageData
}

export async function registerCompany(values) {
  const fetchedPackageData = await getPackageById(values.packageId)
  const fetchedCarouselImages = await Promise.all(
    values.images.map(blobToBase64),
  )

  // LOGO IMAGES
  let logoImage = null

  if (values.logo) {
    const fetchedLogoImage = await blobToBase64(values.logo)
    logoImage = {
      image_id: `${Date.now()}`,
      img: fetchedLogoImage,
      companyId: '',
      contentType: '',
      ext: '',
    }
  }

  // CAROUSEL IMAGES
  const carouselImages = fetchedCarouselImages.map(image => {
    const image_id = `${Date.now()}`
    const finalImg = {
      image_id: image_id,
      img: image,
      companyId: '',
      contentType: '',
      ext: '',
    }

    return finalImg
  })

  const today = new Date().toLocaleDateString('en-GB')

  const newCompanyObj = {
    id: `${Math.random()}`,
    name: values.name || '',
    fixed_line_phone_number: values.phoneNumber1 || '',
    mobile_phone_number: values.phoneNumber2 || '',
    address: values.streetAddress || '',
    location: values.townVillage.label,
    village: values.townVillage.label,
    district: values.townVillage.label,
    latitude: values.latLng.lat,
    longitude: values.latLng.lng,
    map_url: '',
    website_url: '',
    logo_image: logoImage || '',
    carousel_images: carouselImages,
    email_address: values.email || '',
    whatsapp: values.whatsApp || '',
    facebook: values.facebook || '',
    instagram: values.instagram || '',
    linkedin: values.linkedIn || '',
    description: values.description || '',
    keywords: values.subCategories.map(({label}) => label),
    subCategoryIds: values.subCategories.map(({value}) => value),
    is_24_7: values.isOpened247,
    on_public_holiday: values.isOpenedOnPublicHolidays,
    package_id: values.packageId || '',
    date_created: today,
    date_updated: today,
    status: 'inactive',
    package_name: fetchedPackageData.name,
    company_opening_hours: {
      monday: {
        start: values.mondayStartTime,
        end: values.mondayEndTime,
        allDay: values.mondayAllDay,
        isOpened: !values.mondayIsClosed,
      },
      tuesday: {
        start: values.tuesdayStartTime,
        end: values.tuesdayEndTime,
        allDay: values.tuesdayAllDay,
        isOpened: !values.tuesdayIsClosed,
      },
      wednesday: {
        start: values.wednesdayStartTime,
        end: values.wednesdayEndTime,
        allDay: values.wednesdayAllDay,
        isOpened: !values.wednesdayIsClosed,
      },
      thursday: {
        start: values.thursdayStartTime,
        end: values.thursdayEndTime,
        allDay: values.thursdayAllDay,
        isOpened: !values.thursdayIsClosed,
      },
      friday: {
        start: values.fridayStartTime,
        end: values.fridayEndTime,
        allDay: values.fridayAllDay,
        isOpened: !values.fridayIsClosed,
      },
      saturday: {
        start: values.saturdayStartTime,
        end: values.saturdayEndTime,
        allDay: values.saturdayAllDay,
        isOpened: !values.saturdayIsClosed,
      },
      sunday: {
        start: values.sundayStartTime,
        end: values.sundayEndTime,
        allDay: values.sundayAllDay,
        isOpened: !values.sundayIsClosed,
      },
      isOpened247: values.isOpened247,
      isOpenedOnPublicHolidays: values.isOpenedOnPublicHolidays,
    },
  }

  return new Promise((resolve, reject) => {
    post('company', newCompanyObj)
      .then(res => {
        resolve(res?.data)
      })
      .catch(e => {
        reject(e)
      })
  })
}

// export function getPopularSuggestions(lat, lng) {
//   return new Promise((resolve, reject) => {
//     // MISSING_INFO:Suggestion API not ready
//     // get(`categoriesAndSubCategories`)
//     //   .then(res => {
//     //     resolve(res?.data || [])
//     //   })
//     //   .catch(e => {
//     //     reject(e)
//     //   })

//     resolve([
//       {
//         _id: '001',
//         label: 'Spa',
//       },
//       {
//         _id: '002',
//         label: 'Spa',
//       },
//       {
//         _id: '003',
//         label: 'Hair Saloon',
//       },
//       {
//         _id: '004',
//         label: 'Pedicure',
//       },
//       {
//         _id: '005',
//         label: 'Pedicure',
//       },
//       {
//         _id: '006',
//         label: 'Hair Saloon',
//       },
//       {
//         _id: '007',
//         label: 'Pedicure',
//       },
//       {
//         _id: '008',
//         label: 'Hair Saloon',
//       },
//     ])
//   })
// }
