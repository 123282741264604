import {StyledSvg} from './styles'

function AccessibilityIcon({size, color, width, height, ...props}) {
  return (
    <StyledSvg
      viewBox="0 0 18 21"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      $size={size}
      $width={width}
      $height={height}
      {...props}
    >
      <path
        d="M9 4.5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm7.5 14h-1v-5a1 1 0 0 0-1-1h-5v-2h5a1 1 0 0 0 0-2h-5v-2a1 1 0 1 0-2 0v7a1 1 0 0 0 1 1h5v5a1 1 0 0 0 1 1h2a1 1 0 0 0 0-2Zm-6.8-1.6a4 4 0 0 1-7.2-2.4 4 4 0 0 1 2.4-3.66A1.003 1.003 0 0 0 4.1 9a6 6 0 1 0 7.2 9.1 1 1 0 0 0-1.6-1.2Z"
        fill={color || '#000'}
      />
    </StyledSvg>
  )
}

export default AccessibilityIcon
