import styled from 'styled-components'

const StyledBackContainer = styled.div`
  align-self: stretch;
  position: relative;
  position: sticky;
  top: 0;
  background-color: ${({theme}) => theme.colors.white};
  z-index: 9;
`

export {StyledBackContainer}
