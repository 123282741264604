import {StyledCheckboxField, StyledCheckboxLabel} from './styles'

export default function CheckboxField({id, label, disabled, ...props}) {
  return (
    <div>
      <StyledCheckboxField
        {...props}
        id={id}
        type="checkbox"
        disabled={disabled}
      />
      <StyledCheckboxLabel htmlFor={id} $disabled={disabled}>
        {label}
      </StyledCheckboxLabel>
    </div>
  )
}
