import {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {useTheme} from 'styled-components'
import Clickable, {Button} from '../Clickable'
import Icon from '../Icon'
import {StyledBackContainer} from './styles'

function Back() {
  const theme = useTheme()
  const navigate = useNavigate()
  const {t} = useTranslation()

  const goBack = useCallback(() => navigate(-1), [navigate])

  return (
    <StyledBackContainer>
      <Clickable as={Button} title={t('common:back')} onClick={goBack}>
        <Icon name="arrowLeft" width="1.25rem" color={theme.colors.black} />
      </Clickable>
    </StyledBackContainer>
  )
}

export default Back
