import {Suspense} from 'react'
import styled, {ThemeProvider} from 'styled-components'
import {ToastContainer} from 'react-toastify'
import AppRoutes from './components/AppRoutes'
import {lightTheme} from './styles/themes'
import 'react-toastify/dist/ReactToastify.css'
import './App.css'
import Initializer from './components/Initializer'

const StyledSuspenseFallback = styled.div`
  width: ${({$width}) => $width ?? '100vw'};
  max-width: 100vw;
  height: ${({$height}) => $height ?? '100vh'};
  max-height: 100vh;
  background-color: ${({theme}) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
`

function App() {
  return (
    <ThemeProvider theme={lightTheme}>
      <Suspense fallback={<StyledSuspenseFallback />}>
        <div className="App">
          <Initializer>
            <ToastContainer theme="dark" />
            <AppRoutes />
          </Initializer>
        </div>
      </Suspense>
    </ThemeProvider>
  )
}

export default App
