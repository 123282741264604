import {Field} from 'formik'
import styled from 'styled-components'
import {formInputStyles} from '../../../../../../../styles/common'

export const StyledTimeFieldWrapper = styled.div`
  max-width: 100%;
`

export const StyledTimeField = styled(Field)`
  ${formInputStyles}

  &:disabled {
    color: ${({theme}) => theme.colors.lunarGray30};
    border-color: ${({theme}) => theme.colors.lunarGray30};
    background-color: ${({theme}) => theme.colors.lunarGray0};
  }
`
