import {useFormikContext} from 'formik'
import {useCallback} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {Decimal} from 'decimal.js'
import {StyledFieldSet, StyledLegendWrapper} from '../../styles'
import {
  StyledTableData,
  StyledBusinessName,
  StyledParagraph,
  StyledPaymentProviders,
  StyledSectionHeading,
  StyledStep,
  StyledSteps,
  StyledTable,
  StyledTableHeading,
  StyledWrapper,
  StyledNote,
} from './styles'

export default function Step4({price, currency = 'MUR'}) {
  const {t} = useTranslation()
  const {values} = useFormikContext()

  const currencyFormatter = useCallback(() => {
    const formatter = Intl.NumberFormat('en-MU', {
      style: 'currency',
      currency,
      maximumFractionDigits: 2,
      currencyDisplay: 'narrowSymbol',
    })

    return formatter
  }, [currency])

  const currencySymbol = useCallback(
    amount => {
      const formattedCurrencyParts = currencyFormatter().formatToParts(amount)
      const formattedCurrencyPart = formattedCurrencyParts.find(
        part => part.type === 'currency',
      )

      const formattedCurrencySymbol = formattedCurrencyPart?.value || ''

      return formattedCurrencySymbol
    },
    [currencyFormatter],
  )

  const decimalToDP = useCallback((decimalAmount, decimalPlaces = 2) => {
    const finalAmount = decimalAmount
      .toDecimalPlaces(decimalPlaces)
      .toFixed(decimalPlaces)

    return finalAmount
  }, [])

  const decimalPrice = useCallback(() => {
    const decimalFinalPrice = new Decimal(price || 0)

    return decimalFinalPrice
  }, [price])

  const displayedPrice = useCallback(
    () => decimalToDP(decimalPrice()),
    [decimalPrice, decimalToDP],
  )

  const displayedPriceCurrency = useCallback(
    () => currencySymbol(decimalPrice().toNumber()),
    [decimalPrice, currencySymbol],
  )

  const renderPaymentProviders = () => {
    const providerLogos = [
      {
        name: 'juice',
        path: '/images/juice.png',
      },
      {
        name: 'myt-money',
        path: '/images/myt-money.png',
      },
    ]

    return providerLogos.map(providerLogo => (
      <img
        key={providerLogo.name}
        src={providerLogo.path}
        height={32}
        alt={providerLogo.name}
      />
    ))
  }

  return (
    <StyledWrapper>
      <Trans
        components={{
          para: <StyledParagraph />,
        }}
      >
        {t('registrationPage:step4.thankYou')}
      </Trans>
      <StyledFieldSet>
        <StyledLegendWrapper>
          <StyledSectionHeading>
            {t('registrationPage:step4.summary.paymentDetails.label')}
          </StyledSectionHeading>
          <StyledBusinessName>{values.name}</StyledBusinessName>
          <StyledTable width="100%">
            <tbody width="100%">
              <tr>
                <StyledTableHeading scope="row" align="left" $bold>
                  {t(
                    'registrationPage:step4.summary.paymentDetails.finalPrice',
                  )}
                </StyledTableHeading>
                <StyledTableData align="right" valign="top" $bold $currency>
                  {displayedPriceCurrency()}
                </StyledTableData>
                <StyledTableData align="right" valign="top" $spaced $bold>
                  {displayedPrice()}
                </StyledTableData>
              </tr>
            </tbody>
          </StyledTable>
          <StyledSectionHeading>
            {t('registrationPage:step4.summary.steps.label')}
          </StyledSectionHeading>
          <Trans
            components={{
              list: <StyledSteps />,
              item: <StyledStep />,
              bold: <b />,
            }}
          >
            {t('registrationPage:step4.summary.steps.instructions')}
          </Trans>
        </StyledLegendWrapper>
      </StyledFieldSet>
      <StyledNote>{t('registrationPage:step4.note')}</StyledNote>
      <StyledPaymentProviders>
        {renderPaymentProviders()}
      </StyledPaymentProviders>
    </StyledWrapper>
  )
}
