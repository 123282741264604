import {useCallback} from 'react'
import {useField, useFormikContext} from 'formik'
import PhoneInput from 'react-phone-number-input'
import {StyledPhoneFieldWrapper} from './styles'
import {StyledFieldError} from '../../../../../../styles/common'
import 'react-phone-number-input/style.css'
import {setCustomFormikFieldValue} from '../../../../../../utils/functions'

export default function PhoneField({name, ...props}) {
  const [field, meta] = useField(name)
  const {setFieldValue, setFieldTouched} = useFormikContext()

  const onChange = useCallback(
    phoneNum => {
      setCustomFormikFieldValue({
        name,
        value: phoneNum,
        setFieldValue,
        setFieldTouched,
      })
    },
    [name, setFieldTouched, setFieldValue],
  )

  const onBlur = useCallback(
    () => setFieldTouched(name, true),
    [name, setFieldTouched],
  )

  const error = useCallback(
    () => meta.touched && meta.error,
    [meta.error, meta.touched],
  )

  return (
    <StyledPhoneFieldWrapper>
      <PhoneInput
        name={name}
        {...props}
        defaultCountry="MU"
        value={field.value}
        onChange={onChange}
        onBlur={onBlur}
        displayInitialValueAsLocalNumber
      />
      <StyledFieldError $paddingLeft="54px">{error()}</StyledFieldError>
    </StyledPhoneFieldWrapper>
  )
}
