import {t} from 'i18next'
import {Link} from 'react-router-dom'
import Clickable from '../../components/Clickable'
import {StyledErrorBody, StyledErrorH1, StyledWrapper} from './styles'

export default function ErrorPage() {
  return (
    <StyledWrapper>
      <StyledErrorH1>{t(`errorPage:404`)}</StyledErrorH1>
      <StyledErrorBody>{t(`errorPage:body`)}</StyledErrorBody>
      <Clickable as={Link} $variant="navy" to="/" $rounded>
        {t(`errorPage:backToHome`)}
      </Clickable>
    </StyledWrapper>
  )
}
