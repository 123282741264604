import {StyledSvg} from './styles'

function InstagramIcon({size, color, width, height, ...props}) {
  return (
    <StyledSvg
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      $size={size}
      $width={width}
      $height={height}
      {...props}
    >
      <circle cx={20} cy={20} r={20} fill={color || '#C13584'} />
      <path
        d="M20 10.162c3.204 0 3.584.012 4.849.07 1.17.054 1.805.249 2.228.413.56.218.96.478 1.38.898.42.42.68.82.898 1.38.164.423.36 1.058.413 2.228.058 1.265.07 1.645.07 4.849s-.012 3.584-.07 4.849c-.053 1.17-.249 1.805-.413 2.228-.218.56-.478.96-.898 1.38-.42.42-.82.68-1.38.898-.423.164-1.058.36-2.228.413-1.265.058-1.645.07-4.849.07s-3.584-.012-4.849-.07c-1.17-.054-1.805-.249-2.228-.413a3.718 3.718 0 0 1-1.38-.898c-.42-.42-.68-.82-.898-1.38-.164-.423-.36-1.058-.413-2.228-.058-1.265-.07-1.645-.07-4.85 0-3.203.012-3.583.07-4.848.054-1.17.249-1.805.413-2.228.218-.56.478-.96.898-1.38.42-.42.82-.68 1.38-.898.423-.164 1.058-.36 2.228-.413 1.265-.058 1.645-.07 4.849-.07ZM20 8c-3.259 0-3.668.014-4.948.072-1.277.058-2.15.261-2.912.558a5.881 5.881 0 0 0-2.126 1.384A5.88 5.88 0 0 0 8.63 12.14c-.297.763-.5 1.635-.558 2.912C8.014 16.332 8 16.741 8 20c0 3.259.014 3.668.072 4.948.058 1.277.261 2.15.558 2.912.307.79.717 1.459 1.384 2.126a5.88 5.88 0 0 0 2.126 1.384c.763.297 1.635.5 2.912.558 1.28.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 1.277-.059 2.15-.261 2.912-.558a5.88 5.88 0 0 0 2.126-1.384 5.882 5.882 0 0 0 1.384-2.126c.297-.763.5-1.635.558-2.912.058-1.28.072-1.689.072-4.948 0-3.259-.014-3.668-.072-4.948-.059-1.277-.261-2.15-.558-2.912a5.881 5.881 0 0 0-1.384-2.126A5.881 5.881 0 0 0 27.86 8.63c-.763-.297-1.635-.5-2.912-.558C23.668 8.014 23.259 8 20 8Z"
        fill="#fff"
      />
      <path
        d="M20.005 13.843a6.162 6.162 0 1 0 0 12.324 6.162 6.162 0 0 0 0-12.324Zm0 10.163a4 4 0 1 1 0-8 4 4 0 0 1 0 8ZM27.85 13.596a1.44 1.44 0 1 1-2.88 0 1.44 1.44 0 0 1 2.88 0Z"
        fill="#fff"
      />
    </StyledSvg>
  )
}

export default InstagramIcon
