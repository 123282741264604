/* eslint-disable react/destructuring-assignment */
import {useCallback} from 'react'
import {useField, useFormikContext} from 'formik'
import {components, createFilter} from 'react-select'
import {StyledSelect, StyledSelectWrapper} from './styles'
import {StyledFieldError} from '../../../../../../styles/common'
import {setCustomFormikFieldValue} from '../../../../../../utils/functions'

const handleHeaderClick = id => {
  const node = document.querySelector(`#${id}`).parentElement.nextElementSibling
  const classes = node.classList
  if (classes.contains('group-heading-expanded')) {
    node.classList.remove('group-heading-expanded')
  } else {
    node.classList.add('group-heading-expanded')
  }
}

function GroupHeading(props) {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className="group-heading-wrapper"
      // eslint-disable-next-line react/destructuring-assignment
      onClick={() => handleHeaderClick(props.id)}
    >
      <components.GroupHeading {...props} />
    </div>
  )
}

function Option(props) {
  const {onMouseMove, onMouseOver, ...rest} = props.innerProps
  const newProps = {...props, innerProps: rest}

  return (
    <div>
      <components.Option {...newProps}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{' '}
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label>{props.label}</label>
      </components.Option>
    </div>
  )
}

export default function SubCategoriesField({name, ...props}) {
  const {setFieldValue, setFieldTouched} = useFormikContext()
  const [field, meta] = useField({name})

  const onChange = useCallback(
    value => {
      setCustomFormikFieldValue({
        name,
        value,
        setFieldValue,
        setFieldTouched,
      })
    },
    [name, setFieldTouched, setFieldValue],
  )

  const onBlur = useCallback(
    () => setFieldTouched(name, true),
    [name, setFieldTouched],
  )

  const error = useCallback(
    () => meta.touched && meta.error,
    [meta.error, meta.touched],
  )

  return (
    <StyledSelectWrapper>
      <StyledSelect
        name={name}
        value={field.value}
        onChange={onChange}
        onBlur={onBlur}
        $error={!!error()}
        components={{GroupHeading, Option}}
        hideSelectedOptions={false}
        filterOption={createFilter({ignoreAccents: false})}
        openMenuOnFocus
        openMenuOnClick
        {...props}
      />
      <StyledFieldError>{error()}</StyledFieldError>
    </StyledSelectWrapper>
  )
}
