import {Link} from 'react-router-dom'
import styled from 'styled-components'
import {waveBackgroundStyles} from '../../../styles/common'
import Clickable from '../../Clickable'

const StyledMenuContainer = styled.div`
  align-self: stretch;
  position: relative;

  .bounce {
    &-enter {
      left: -100%;

      &-active {
        left: 0;
        transition: left 0.7s cubic-bezier(0.05, 0.85, 0.28, 0.95);
      }
    }

    &-exit {
      left: 0;

      &-active {
        left: -100%;
        transition: left 0.7s cubic-bezier(0.05, 0.85, 0.28, 0.95);
      }
    }
  }
`

const StyledMenu = styled.div`
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  background-color: ${({theme}) => theme.colors.white};
  position: absolute;
  top: 0%;
  left: 0%;
  z-index: 999;
  display: flex;
  flex-direction: column;

  ${waveBackgroundStyles}
`

const StyledTop = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const StyledContent = styled.div`
  padding: 0 1.5rem 1.5rem 1.5rem;
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`

const StyledGreetings = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
  align-items: center;
  margin: 3rem 0;
  font-weight: 700;
  font-size: 1.5rem;
  color: ${({theme}) => theme.colors.black};
`

const StyledLink = styled(Link)`
  font-size: 1.5rem;
  color: ${({theme}) => theme.colors.black};
  text-decoration: none;
  padding: 0.5rem 0;
`

const StyledFooter = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
  padding-bottom: 2rem;
`

const StyledClickable = styled(Clickable)`
  padding: 0;
`

export {
  StyledMenuContainer,
  StyledMenu,
  StyledTop,
  StyledContent,
  StyledGreetings,
  StyledLink,
  StyledFooter,
  StyledClickable,
}
