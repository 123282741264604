import {useCallback, useEffect, useMemo, useState} from 'react'
import {useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {toast} from 'react-toastify'
import {getOfficeById} from '../../services/api'
import DefaultLayout from '../../layouts/DefaultLayout'
import ImageCarousel from '../../components/ImageCarousel'
import OperatingSchedule from '../../components/OperatingSchedule'
import Icon from '../../components/Icon'
import Clickable, {Button, Anchor} from '../../components/Clickable'
import ButtonGroup from '../../components/ButtonGroup'
import CallWrappper from '../../components/CallWrapper'
import {PageHeading1} from '../../styles/common'
import {
  OfficeDescription,
  OfficeCarouselWrapper,
  OfficeHeadingWrapper,
  OfficeWrapper,
  OfficeSocialWrapper,
  OfficeHoursWrapper,
  OfficeOperatingScheduleWrapper,
  OfficeHoursText,
  OfficeBtnWrapper,
  OfficeHeaderWrapper,
  OfficePlaceholderWrapper,
  OfficeCompanyLogoWrapper,
  OfficeStyledClickable,
  OfficePublicHolidaysOperatingSchedule,
} from './styles'
import colors from '../../styles/colors'
import {
  retrieveCompanyAddress,
  retrieveGoogleMapsUrl,
  sanitizePhoneNumbers,
} from '../../utils/functions'
import sanitizeWhatsapp from '../../utils/sanitizeWhatsapp'

function OfficePage() {
  const {t} = useTranslation()
  const {id} = useParams()
  const [office, setOffice] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const {i18n} = useTranslation()

  const includes = office?.package?.include

  useEffect(() => {
    let setState = newOffice => {
      setOffice(newOffice)
      setIsLoading(false)
    }

    getOfficeById(id)
      .then(res => {
        setState?.(res)
      })
      .catch(e => {
        console.log('e :>> ', e)
        toast.error(t('common:errors.unknownRefresh'))
        setIsLoading(false)
      })

    return () => {
      setState = null
    }
  }, [id, t])

  const socialLinks = useMemo(
    () => [
      {
        name: 'instagram',
        labelKey: 'instagram',
        href: office?.company_instagram,
      },
      {
        name: 'linkedIn',
        labelKey: 'linkedIn',
        href: office?.company_linkedin,
      },
      {
        name: 'facebook',
        labelKey: 'facebook',
        href: office?.company_facebook,
      },
      {
        name: 'whatsApp',
        labelKey: 'whatsApp',
        href: office?.company_whatsapp
          ? `https://api.whatsapp.com/send?phone=${sanitizeWhatsapp(
              office?.company_whatsapp,
            )}`
          : null,
      },
    ],
    [
      office?.company_facebook,
      office?.company_instagram,
      office?.company_linkedin,
      office?.company_whatsapp,
    ],
  )

  const officeHours = useCallback(() => {
    const baseOpeningHours = office?.company_opening_hours || {}
    const {monday, tuesday, wednesday, thursday, friday, saturday, sunday} =
      baseOpeningHours

    const finalOfficeHours = {
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      sunday,
    }

    return finalOfficeHours
  }, [office?.company_opening_hours])

  const sanitizedPhoneNums = useCallback(
    () => sanitizePhoneNumbers(office?.phoneNumbers),
    [office?.phoneNumbers],
  )

  const noContact = useCallback(
    () => (sanitizedPhoneNums().length || 0) === 0,
    [sanitizedPhoneNums],
  )

  const retrieveAddress = useCallback(
    () => retrieveCompanyAddress(office?.address, i18n.language),
    [i18n.language, office?.address],
  )

  const mapsUrl = useCallback(
    () => retrieveGoogleMapsUrl(office?.address),
    [office?.address],
  )

  const renderOfficeHours = useCallback(
    () => (
      <OfficeOperatingScheduleWrapper>
        {Object.entries(officeHours())?.map(([key, value]) => (
          <OperatingSchedule
            key={key}
            day={t(`common:days.${key}.long`)}
            startTime={value.start}
            endTime={value.end}
            isOpened={value.isOpened}
            allDay={value.allDay}
            isOpened247={!!office?.company_opening_hours?.isOpened247}
          />
        ))}
      </OfficeOperatingScheduleWrapper>
    ),
    [office?.company_opening_hours?.isOpened247, officeHours, t],
  )

  const renderSocialLinks = useCallback(
    () =>
      socialLinks.map(item =>
        item.href ? (
          <OfficeStyledClickable
            key={item.name}
            as={Anchor}
            href={item.href}
            target="_blank"
            title={t(`officePage:${item.labelKey}`)}
          >
            <Icon name={item.name} size="2.5rem" />
          </OfficeStyledClickable>
        ) : null,
      ),
    [socialLinks, t],
  )

  const renderImage = useCallback(() => {
    if (office?.logo) {
      return (
        <OfficeCompanyLogoWrapper
          // src={`${process.env.REACT_APP_API_URL}${office?.logo?.filename}`}
          src={`${office?.logo}`}
          alt={office?.company_name}
          width="60px"
          height="60px"
        />
      )
    }
    return (
      <OfficePlaceholderWrapper>
        <Icon name="theFutur" width="1.875rem" />
      </OfficePlaceholderWrapper>
    )
  }, [office?.company_name, office?.logo])

  const renderOpenedOnPublicHolidays = useCallback(
    () => (
      <OfficePublicHolidaysOperatingSchedule>
        {t(
          `officePage:${
            office?.company_opening_hours?.isOpenedOnPublicHolidays
              ? 'isOpenedOnPublicHolidays'
              : 'isClosedOnPublicHolidays'
          }`,
        )}
      </OfficePublicHolidaysOperatingSchedule>
    ),
    [office?.company_opening_hours?.isOpenedOnPublicHolidays, t],
  )

  return (
    <DefaultLayout loading={isLoading} noBottomPadding>
      <OfficeWrapper>
        <OfficeHeaderWrapper>
          {renderImage()}
          <OfficeHeadingWrapper>
            <PageHeading1>{office?.company_name}</PageHeading1>
            <OfficeDescription>{retrieveAddress()}</OfficeDescription>
          </OfficeHeadingWrapper>
        </OfficeHeaderWrapper>

        <OfficeCarouselWrapper>
          <ImageCarousel aspectRatio="4 / 3" items={office?.carousel_images} />
        </OfficeCarouselWrapper>

        {includes?.description && (
          <OfficeDescription>{office?.company_description}</OfficeDescription>
        )}

        {includes?.openingHours && (
          <OfficeHoursWrapper open>
            <OfficeHoursText>{t('officePage:hourText')}</OfficeHoursText>
            {renderOfficeHours()}
            {renderOpenedOnPublicHolidays()}
          </OfficeHoursWrapper>
        )}

        {includes?.socialLinks && (
          <OfficeSocialWrapper>{renderSocialLinks()}</OfficeSocialWrapper>
        )}
      </OfficeWrapper>

      {(includes?.email || includes?.call || includes?.location) && (
        <OfficeBtnWrapper>
          <ButtonGroup width="100%" $rounded>
            {includes?.call && (
              <CallWrappper phoneNumbers={sanitizedPhoneNums()}>
                <Clickable
                  as={Button}
                  $variant="success"
                  width="100%"
                  $large
                  disabled={noContact()}
                  $roundedStart
                >
                  {t('officePage:btnGroupText.phone')}
                  <Icon name="phone" size="1.5em" color={colors.white} />
                </Clickable>
              </CallWrappper>
            )}
            {office?.package?.include?.email && (
              <Clickable
                as={Anchor}
                $variant="info"
                width="100%"
                $large
                href={
                  office?.company_email
                    ? `mailto:${office?.company_email}`
                    : null
                }
                disabled={!office?.company_email}
              >
                {t('officePage:btnGroupText.email')}
                <Icon name="envelopeUpload" size="1.5em" color={colors.white} />
              </Clickable>
            )}
            {office?.package?.include?.location &&
              office?.address?.lat &&
              office?.address?.lat && (
                <Clickable
                  as={Anchor}
                  $variant="danger"
                  width="100%"
                  $large
                  href={mapsUrl()}
                  target="_blank"
                >
                  {t('officePage:btnGroupText.direction')}
                  <Icon name="marker" size="1.5em" color={colors.white} />
                </Clickable>
              )}
          </ButtonGroup>
        </OfficeBtnWrapper>
      )}
    </DefaultLayout>
  )
}

export default OfficePage
