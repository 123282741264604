import styled from 'styled-components'

export const StyledLvl0List = styled.ol`
  color: ${({theme}) => theme.colors.black};
  font-size: 16px;
  text-align: left;
  width: 100%;
  margin: 24px 0 0 0;
  counter-reset: lvl-0;
  list-style: none;
`

export const StyledLvl0ListItem = styled.li`
  color: ${({theme}) => theme.colors.black};
  position: relative;
  font-weight: 600;
  margin: 0 0 8px 0;

  &:before {
    content: counter(lvl-0) '.';
    counter-increment: lvl-0;
    position: absolute;
    margin-right: 100%;
    right: 0.5rem;
  }
`

export const StyledLvl1List = styled.ol`
  color: ${({theme}) => theme.colors.black};
  font-size: 14px;
  font-weight: 400;
  margin: 8px 0 0 0;
  counter-reset: lvl-1;
  list-style: none;
`

export const StyledLvl1ListItem = styled.li`
  color: ${({theme}) => theme.colors.black};
  position: relative;
  margin: 0 0 8px 0;

  &:before {
    content: counter(lvl-0) '.' counter(lvl-1);
    counter-increment: lvl-1;
    position: absolute;
    margin-right: 100%;
    right: 0.5rem;
  }
`

export const StyledLvl2List = styled.ol`
  color: ${({theme}) => theme.colors.black};
  font-size: 14px;
  margin: 8px 0 0 24px;
  counter-reset: lvl-2;
  list-style: none;
  padding: 0;
`

export const StyledLvl2ListItem = styled.li`
  color: ${({theme}) => theme.colors.black};
  position: relative;

  &:before {
    content: '(' counter(lvl-2, lower-alpha) ')';
    counter-increment: lvl-2;
    position: absolute;
    margin-right: 100%;
    right: 0.5rem;
  }
}
`
