import {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import countries from 'i18n-iso-countries'
import enCountryList from 'i18n-iso-countries/langs/en.json'
import frCountryList from 'i18n-iso-countries/langs/fr.json'
import Icon from '../../../../components/Icon'
import TextField from '../../../../components/TextField'
import MapField from './components/MapField'
import CountryField from './components/CountryField'
import PhoneField from './components/PhoneField'
import SubCategoriesField from './components/SubCategoriesField'
import OperatingHoursRow from './components/OperatingHoursRow'
import {requiredFieldLabel} from '../../../../utils/functions'
import {
  FieldsWrapper,
  StyledFieldSetFieldsWrapper,
  StyledFieldSetHRule,
  StyledSocialIconWrapper,
  StyledSocialLinkTextField,
  StyledSocialLinkWrapper,
} from './styles'
import CheckboxField from './components/CheckboxField'
import {StyledFieldSet, StyledLegendWrapper} from '../../styles'
import AreaField from './components/AreaField'

countries.registerLocale(enCountryList)
countries.registerLocale(frCountryList)

export default function Step2({
  maxPhones,
  email,
  socialLinks,
  map,
  nestedSubCategories,
}) {
  const {t} = useTranslation()

  const renderNameField = useCallback(
    () => (
      <TextField
        name="name"
        label={requiredFieldLabel(t('registrationPage:step2.form.name'))}
        placeholder={requiredFieldLabel(t('registrationPage:step2.form.name'))}
        $large
      />
    ),
    [t],
  )

  const renderDescriptionField = useCallback(
    () => (
      <TextField
        name="description"
        label={requiredFieldLabel(t('registrationPage:step2.form.description'))}
        placeholder={requiredFieldLabel(
          t('registrationPage:step2.form.description'),
        )}
        $large
      />
    ),
    [t],
  )

  const renderAddressFields = useCallback(
    () => (
      <StyledFieldSet>
        <StyledLegendWrapper>
          <legend>{t('registrationPage:step2.form.address.label')}</legend>
        </StyledLegendWrapper>
        <StyledFieldSetFieldsWrapper>
          <TextField
            name="streetAddress"
            label={t('registrationPage:step2.form.address.streetAddress')}
            placeholder={t('registrationPage:step2.form.address.streetAddress')}
          />
          {/* <TextField
            name="townVillage"
            label={requiredFieldLabel(
              t('registrationPage:step2.form.address.townVillage'),
            )}
            placeholder={requiredFieldLabel(
              t('registrationPage:step2.form.address.townVillage'),
            )}
          /> */}
          <AreaField
            name="townVillage"
            placeholder={requiredFieldLabel(
              t('registrationPage:step2.form.address.townVillage'),
            )}
            cacheOptions
          />
          <CountryField
            name="country"
            placeholder={requiredFieldLabel(
              t('registrationPage:step2.form.address.country'),
            )}
            isDisabled
          />
          {!!map && (
            <MapField
              name="latLng"
              markerNote={t(
                'registrationPage:step2.form.address.map.markerNote',
              )}
              geolocationNote={t(
                'registrationPage:step2.form.address.map.geolocationNote',
              )}
            />
          )}
        </StyledFieldSetFieldsWrapper>
      </StyledFieldSet>
    ),
    [map, t],
  )

  const renderSubCategoriesField = useCallback(
    () => (
      <StyledFieldSet>
        <StyledLegendWrapper>
          <legend>
            {t('registrationPage:step2.form.subCategories.label')}
          </legend>
        </StyledLegendWrapper>
        <SubCategoriesField
          name="subCategories"
          placeholder={requiredFieldLabel(
            t('registrationPage:step2.form.subCategories.placeholder'),
          )}
          options={nestedSubCategories}
          isMulti
          isSearchable={false}
          closeMenuOnSelect={false}
          blurInputOnSelect={false}
          closeMenuOnScroll
        />
      </StyledFieldSet>
    ),
    [nestedSubCategories, t],
  )

  const renderPhoneNumberFields = useCallback(
    () =>
      maxPhones > 0 && (
        <StyledFieldSet>
          <StyledLegendWrapper>
            <legend>
              {t('registrationPage:step2.form.phoneNumbers.label')}
            </legend>
          </StyledLegendWrapper>
          <StyledFieldSetFieldsWrapper>
            <PhoneField
              name="phoneNumber1"
              label={requiredFieldLabel(
                t('registrationPage:step2.form.phoneNumbers.phoneNumber1'),
              )}
              placeholder={requiredFieldLabel(
                t('registrationPage:step2.form.phoneNumbers.phoneNumber1'),
              )}
            />
            {maxPhones > 1 && (
              <PhoneField
                name="phoneNumber2"
                label={t(
                  'registrationPage:step2.form.phoneNumbers.phoneNumber2',
                )}
                placeholder={t(
                  'registrationPage:step2.form.phoneNumbers.phoneNumber2',
                )}
              />
            )}
          </StyledFieldSetFieldsWrapper>
        </StyledFieldSet>
      ),
    [maxPhones, t],
  )

  const renderEmailField = useCallback(
    () =>
      !!email && (
        <TextField
          name="email"
          label={t('registrationPage:step2.form.email')}
          placeholder={t('registrationPage:step2.form.email')}
          $large
        />
      ),
    [email, t],
  )

  const renderOperatingHoursFields = useCallback(
    () => (
      <StyledFieldSet>
        <StyledLegendWrapper>
          <legend>
            {t('registrationPage:step2.form.operatingHours.label')}
          </legend>
        </StyledLegendWrapper>
        <StyledFieldSetFieldsWrapper>
          <OperatingHoursRow day="monday" />
          <OperatingHoursRow day="tuesday" />
          <OperatingHoursRow day="wednesday" />
          <OperatingHoursRow day="thursday" />
          <OperatingHoursRow day="friday" />
          <OperatingHoursRow day="saturday" />
          <OperatingHoursRow day="sunday" />
          <StyledFieldSetHRule />
          <CheckboxField
            id="isOpened247"
            name="isOpened247"
            label={t(`registrationPage:step2.form.operatingHours.isOpened247`)}
          />
          <CheckboxField
            id="isOpenedOnPublicHolidays"
            name="isOpenedOnPublicHolidays"
            label={t(
              `registrationPage:step2.form.operatingHours.isOpenedOnPublicHolidays`,
            )}
          />
        </StyledFieldSetFieldsWrapper>
      </StyledFieldSet>
    ),
    [t],
  )

  const renderSocialLinkFields = useCallback(
    () =>
      socialLinks && (
        <StyledFieldSet>
          <StyledLegendWrapper>
            <legend>
              {t('registrationPage:step2.form.socialLinks.label')}
            </legend>
          </StyledLegendWrapper>
          <StyledFieldSetFieldsWrapper>
            <StyledSocialLinkWrapper>
              <StyledSocialIconWrapper>
                <Icon name="instagram" size="28px" />
              </StyledSocialIconWrapper>
              <StyledSocialLinkTextField>
                <TextField
                  name="instagram"
                  label={t('registrationPage:step2.form.socialLinks.instagram')}
                  placeholder={t(
                    'registrationPage:step2.form.socialLinks.instagram',
                  )}
                />
              </StyledSocialLinkTextField>
            </StyledSocialLinkWrapper>
            <StyledSocialLinkWrapper>
              <StyledSocialIconWrapper>
                <Icon name="twitter" size="28px" />
              </StyledSocialIconWrapper>
              <StyledSocialLinkTextField>
                <TextField
                  name="twitter"
                  label={t('registrationPage:step2.form.socialLinks.twitter')}
                  placeholder={t(
                    'registrationPage:step2.form.socialLinks.twitter',
                  )}
                />
              </StyledSocialLinkTextField>
            </StyledSocialLinkWrapper>
            <StyledSocialLinkWrapper>
              <StyledSocialIconWrapper>
                <Icon name="linkedIn" size="28px" />
              </StyledSocialIconWrapper>
              <StyledSocialLinkTextField>
                <TextField
                  name="linkedIn"
                  label={t('registrationPage:step2.form.socialLinks.linkedIn')}
                  placeholder={t(
                    'registrationPage:step2.form.socialLinks.linkedIn',
                  )}
                />
              </StyledSocialLinkTextField>
            </StyledSocialLinkWrapper>
            <StyledSocialLinkWrapper>
              <StyledSocialIconWrapper>
                <Icon name="facebook" size="28px" />
              </StyledSocialIconWrapper>
              <StyledSocialLinkTextField>
                <TextField
                  name="facebook"
                  label={t('registrationPage:step2.form.socialLinks.facebook')}
                  placeholder={t(
                    'registrationPage:step2.form.socialLinks.facebook',
                  )}
                />
              </StyledSocialLinkTextField>
            </StyledSocialLinkWrapper>
            <StyledSocialLinkWrapper>
              <StyledSocialIconWrapper>
                <Icon name="whatsApp" size="28px" />
              </StyledSocialIconWrapper>
              <StyledSocialLinkTextField>
                <PhoneField
                  name="whatsApp"
                  label={t('registrationPage:step2.form.socialLinks.whatsApp')}
                  placeholder={t(
                    'registrationPage:step2.form.socialLinks.whatsApp',
                  )}
                />
              </StyledSocialLinkTextField>
            </StyledSocialLinkWrapper>
          </StyledFieldSetFieldsWrapper>
        </StyledFieldSet>
      ),
    [socialLinks, t],
  )

  return (
    <FieldsWrapper>
      {renderNameField()}
      {renderDescriptionField()}
      {renderPhoneNumberFields()}
      {renderSubCategoriesField()}
      {renderAddressFields()}
      {renderEmailField()}
      {renderSocialLinkFields()}
      {renderOperatingHoursFields()}
    </FieldsWrapper>
  )
}
