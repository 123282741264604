import AccessibilityIcon from './icons/AccessibilityIcon'
import ArrowLeftIcon from './icons/ArrowLeftIcon'
import ArrowRightIcon from './icons/ArrowRightIcon'
import BrowseIcon from './icons/BrowseIcon'
import CameraIcon from './icons/CameraIcon'
import CloseIcon from './icons/CloseIcon'
import DownwardArrowIcon from './icons/DownwardArrowIcon'
import EnvelopeUploadIcon from './icons/EnvelopeUploadIcon'
import FacebookIcon from './icons/FacebookIcon'
import HamburgerIcon from './icons/HamburgerIcon'
import InstagramIcon from './icons/InstagramIcon'
import LinkedInIcon from './icons/LinkedInIcon'
import LocateIcon from './icons/LocateIcon'
import MarkerIcon from './icons/MarkerIcon'
import MarkerUnderlinedIcon from './icons/MarkerUnderlinedIcon'
import PhoneIcon from './icons/PhoneIcon'
import PreviewIcon from './icons/PreviewIcon'
import SearchIcon from './icons/SearchIcon'
import TheFuturFullIcon from './icons/TheFuturFullIcon'
import TheFuturIcon from './icons/TheFuturIcon'
import TwitterIcon from './icons/TwitterIcon'
import WhatsAppIcon from './icons/WhatsAppIcon'

const iconMap = {
  accessibility: AccessibilityIcon,
  arrowLeft: ArrowLeftIcon,
  arrowRight: ArrowRightIcon,
  browse: BrowseIcon,
  camera: CameraIcon,
  close: CloseIcon,
  downwardArrow: DownwardArrowIcon,
  envelopeUpload: EnvelopeUploadIcon,
  facebook: FacebookIcon,
  marker: MarkerIcon,
  markerUnderlined: MarkerUnderlinedIcon,
  hamburger: HamburgerIcon,
  instagram: InstagramIcon,
  linkedIn: LinkedInIcon,
  locate: LocateIcon,
  phone: PhoneIcon,
  preview: PreviewIcon,
  search: SearchIcon,
  theFutur: TheFuturIcon,
  theFuturFull: TheFuturFullIcon,
  twitter: TwitterIcon,
  whatsApp: WhatsAppIcon,
}

function Icon({name, ...props}) {
  const IconComponent = iconMap[name]

  return <IconComponent {...props} />
}

export default Icon
