import {Trans, useTranslation} from 'react-i18next'
import DefaultLayout from '../../layouts/DefaultLayout'
import {PageHeading1} from '../../styles/common'
import {
  StyledLvl0List,
  StyledLvl0ListItem,
  StyledLvl1List,
  StyledLvl1ListItem,
  StyledLvl2List,
  StyledLvl2ListItem,
} from './styles'

export default function TermsAndConditionsPage() {
  const {t} = useTranslation()

  return (
    <DefaultLayout loading={false}>
      <PageHeading1>{t('termsAndConditionsEndUserPage:title')}</PageHeading1>
      <Trans
        components={{
          'lvl-0-list': <StyledLvl0List />,
          'lvl-0-list-item': <StyledLvl0ListItem />,
          'lvl-1-list': <StyledLvl1List />,
          'lvl-1-list-item': <StyledLvl1ListItem />,
          'lvl-2-list': <StyledLvl2List />,
          'lvl-2-list-item': <StyledLvl2ListItem />,
        }}
      >
        {t('termsAndConditionsEndUserPage:content')}
      </Trans>
    </DefaultLayout>
  )
}
