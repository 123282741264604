import {StyledSvg} from './styles'

function MarkerUnderlinedIcon({size, color, width, height, ...props}) {
  return (
    <StyledSvg
      viewBox="0 0 16 20"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      $size={size}
      $width={width}
      $height={height}
      {...props}
    >
      <path
        d="M8 8.783a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm-.71 6.91a1 1 0 0 0 1.42 0l4.09-4.1a6.79 6.79 0 1 0-9.6 0l4.09 4.1Zm-4.06-9.37a4.81 4.81 0 0 1 2.13-3.55 4.81 4.81 0 0 1 5.28 0 4.82 4.82 0 0 1 .75 7.41L8 13.572l-3.39-3.39a4.77 4.77 0 0 1-1.38-3.86ZM15 17.983H1a1 1 0 0 0 0 2h14a1 1 0 1 0 0-2Z"
        fill={color || '#000'}
      />
    </StyledSvg>
  )
}

export default MarkerUnderlinedIcon
