import colors from './colors'

const darkTheme = {}

const lightTheme = {
  btnSuccessFg: colors.white,
  btnSuccessBg: colors.green,
  btnInfoFg: colors.white,
  btnInfoBg: colors.lightBlue0,
  btnSterlingFg: colors.white,
  btnSterlingBg: colors.blue0,
  btnAshFg: colors.white,
  btnAshBg: colors.ashGray,
  btnNavyFg: colors.white,
  btnNavyBg: colors.darkBlue,
  btnWhiteSmokeFg: colors.lunarGray50,
  btnWhiteSmokeBg: colors.lunarGray10,
  btnDangerFg: colors.white,
  btnDangerBg: colors.red0,
  btnPlainFg: colors.black,
  btnPlainBg: 'transparent',
  rounded0: '0',
  rounded1: '0.375rem',
  rounded2: '1rem',
  rounded3: '2.5rem',
  colors,
}

export {darkTheme, lightTheme}
