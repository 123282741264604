import styled from 'styled-components'
import AsyncSelect from 'react-select/async'

export const StyledSelectWrapper = styled.div`
  width: 100%;
  max-width: 100%;
`

export const StyledSelect = styled(AsyncSelect)`
  width: ${({$width}) => $width || '100%'};
  max-width: 100%;
  font-size: 16px;
  height: 36px;

  & div[class$='control'] {
    box-shadow: none;
    height: 100%;
    min-height: 100%;
    border: 1px solid
      ${({theme, $error}) => theme.colors[$error ? 'red0' : 'lunarGray40']};
    border-radius: ${({theme}) => theme.rounded1};

    &:focus-within {
      outline: 1px solid
        ${({theme, $error}) =>
          `${theme.colors[$error ? 'red0' : 'black']} !important`};
      border: 1px solid
        ${({theme, $error}) =>
          `${theme.colors[$error ? 'red0' : 'black']} !important`};
    }

    & > div:first-child {
      padding: 0 12px;

      & > div[class$='placeholder'] {
        color: ${({theme}) => theme.colors.lunarGray30};
        opacity: 1;
        margin: 0;
      }

      & > div[class$='singleValue'] {
        color: inherit;
        margin: 0;
      }

      & > div:nth-child(2) {
        margin: 0;
      }
    }
  }
`
