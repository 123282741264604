import {useCallback, useState, useEffect} from 'react'
import {useFormikContext} from 'formik'
import {useTranslation} from 'react-i18next'
import Modal from 'react-modal'
import {useTheme} from 'styled-components'
import {toast} from 'react-toastify'
import ButtonGroup from '../../../../components/ButtonGroup'
import Clickable, {Button} from '../../../../components/Clickable'
import Icon from '../../../../components/Icon'
import {TOTAL_REGISTRATION_STEPS} from '../../../../utils/constants'
import {StyledFooter, StyledPreviewImage} from './styles'

export default function Footer({currentStep, setCurrentStep, previewUrl}) {
  const {t} = useTranslation()
  const theme = useTheme()
  const {isValid, isSubmitting, dirty, validateForm} = useFormikContext()
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false)

  useEffect(validateForm, [validateForm, currentStep])

  const disabledNext = useCallback(
    () => !isValid || isSubmitting || !dirty,
    [dirty, isSubmitting, isValid],
  )

  const disabledBack = useCallback(() => isSubmitting, [isSubmitting])

  const goToPrevStep = useCallback(
    () => setCurrentStep(prevVal => prevVal - 1),
    [setCurrentStep],
  )

  const closePreviewModal = useCallback(() => setIsPreviewModalOpen(false), [])

  const renderPreviewModal = useCallback(() => {
    const style = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        padding: 0,
        width: 'fit-content',
        maxWidth: 'fit-content',
        border: 'none',
      },
      overlay: {
        zIndex: 9,
        backgroundColor: `${theme.colors.white}ee`,
      },
    }

    return (
      isPreviewModalOpen && (
        <Modal
          isOpen={isPreviewModalOpen}
          onRequestClose={closePreviewModal}
          style={style}
          shouldCloseOnOverlayClick
          preventScroll
        >
          <StyledPreviewImage src={previewUrl} alt="package preview" />
        </Modal>
      )
    )
  }, [closePreviewModal, isPreviewModalOpen, previewUrl, theme.colors.white])

  const renderBackButton = useCallback(() => {
    const showBackBtn =
      currentStep > 1 && currentStep < TOTAL_REGISTRATION_STEPS

    if (!showBackBtn) return null

    return (
      <Clickable
        as={Button}
        $variant="ash"
        width="50%"
        className="footer-btn"
        onClick={goToPrevStep}
        disabled={disabledBack()}
        $large
      >
        <Icon name="arrowLeft" size="1rem" color={theme.colors.white} />
        <span className="btn-text">{t('common:back')}</span>
      </Clickable>
    )
  }, [currentStep, disabledBack, goToPrevStep, t, theme.colors.white])

  const renderPreviewButton = useCallback(() => {
    const showPreviewBtn = currentStep === 1

    if (!showPreviewBtn) return null

    const previewPackage = () => setIsPreviewModalOpen(true)

    return (
      <Clickable
        as={Button}
        $variant="sterling"
        width="50%"
        className="footer-btn"
        onClick={previewPackage}
        disabled={!previewUrl}
        $large
      >
        <Icon name="preview" size="1rem" color={theme.colors.white} />
        <span className="btn-text">{t('common:preview')}</span>
      </Clickable>
    )
  }, [currentStep, previewUrl, t, theme.colors.white])

  const renderNextButton = useCallback(() => {
    const isLastStep = currentStep === TOTAL_REGISTRATION_STEPS
    const btnVariant = isLastStep ? 'success' : 'navy'
    const showSuccessToast = () =>
      toast.success(t('registrationPage:step4.success'))

    return (
      <Clickable
        as={Button}
        type="submit"
        onClick={isLastStep ? showSuccessToast : null}
        $variant={btnVariant}
        width="50%"
        className="footer-btn"
        disabled={disabledNext()}
        $large
      >
        <span className="btn-text">
          {t(`registrationPage:step${currentStep}.nextStep`)}
        </span>
        <Icon name="arrowRight" size="1rem" color={theme.colors.white} />
      </Clickable>
    )
  }, [currentStep, disabledNext, t, theme.colors.white])

  return (
    <StyledFooter>
      {renderPreviewModal()}
      <ButtonGroup key={`${currentStep > 1}`} $rounded>
        {renderBackButton()}
        {renderPreviewButton()}
        {renderNextButton()}
      </ButtonGroup>
    </StyledFooter>
  )
}
