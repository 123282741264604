import {Field, useField} from 'formik'
import {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {
  PackageCardChoose,
  PackageCardGroup,
  PackageCardPackage,
  PackageCardPrice,
  PackageCardText,
  PackageCardTextWrapper,
  PackageCardWrapper,
} from './styles'

function PackageCard({
  packageType,
  price,
  currency = 'MUR',
  description,
  backgroundColor,
  ...props
}) {
  const {t} = useTranslation()
  const [meta] = useField(props.name)

  const selected = useCallback(
    () => meta.value === props.value,
    [meta.value, props.value],
  )

  const formattedPrice = useCallback(() => {
    const currencyFormatter = Intl.NumberFormat('en-MU', {
      style: 'currency',
      currency,
      maximumFractionDigits: 0,
    })
    const formattedAmt = currencyFormatter.format(price)

    return formattedAmt
  }, [currency, price])

  const actionKey = useCallback(
    () => (selected() ? 'chosen' : 'choose'),
    [selected],
  )

  const renderDescription = useCallback(
    () => (
      <PackageCardTextWrapper>
        {description.map(__html => (
          <PackageCardText key={__html} dangerouslySetInnerHTML={{__html}} />
        ))}
      </PackageCardTextWrapper>
    ),
    [description],
  )

  return (
    <PackageCardWrapper
      $backgroundColor={backgroundColor}
      $selected={selected()}
    >
      <Field type="radio" {...props} />
      <PackageCardGroup>
        <PackageCardPackage>{packageType}</PackageCardPackage>
        <PackageCardPrice>{formattedPrice()}</PackageCardPrice>
        {renderDescription()}
      </PackageCardGroup>
      <PackageCardChoose>
        {t(`registrationPage:${actionKey()}`)}
      </PackageCardChoose>
    </PackageCardWrapper>
  )
}

export default PackageCard
