import {useCallback, useEffect, useState, useRef} from 'react'
import {useTheme} from 'styled-components'
import {useTranslation} from 'react-i18next'
import {toast} from 'react-toastify'
import Cropper from 'react-cropper'
import Modal from 'react-modal'
import imageCompression from 'browser-image-compression'
import Clickable, {Button} from '../Clickable'
import ButtonGroup from '../ButtonGroup'
import Icon from '../Icon'
import {
  MAX_IMAGE_COMPRESSED_SIZE_IN_MB,
  SUPPORTED_IMAGE_EXTENSIONS,
} from '../../utils/constants'
import {parseMathCalc} from '../../utils/functions'
import {
  StyledButtonsWrapper,
  StyledClearButton,
  StyledLabel,
  StyledLoaderWrapper,
  StyledModalButtonGroup,
  StyledPicker,
  StyledPickersWrapper,
  StyledPreview,
  StyledPreviewImage,
} from './styles'
import 'cropperjs/dist/cropper.css'
import {StyledLoader} from '../../styles/common'

Modal.setAppElement('#root')

export default function ImagePicker({
  id,
  width,
  aspectRatio = '1 / 1',
  handleBrowse,
  handleClear,
  maxImageSizeInMB = 2,
  defaultSelectedImage,
  placeholder = false,
}) {
  const theme = useTheme()
  const {t} = useTranslation()
  const [fileReader] = useState(new FileReader())
  const [selectedImage, setSelectedImage] = useState(defaultSelectedImage)
  const [isLoading, setIsLoading] = useState(false)
  const [isCropperModalOpen, setIsCropperModalOpen] = useState(false)
  const browsedImage = useRef(null)
  const tempCroppedImage = useRef(null)

  const isDisabled = useCallback(() => isLoading, [isLoading])

  const calcAspectRatio = useCallback(
    () => parseMathCalc(aspectRatio),
    [aspectRatio],
  )

  const fileInputAcceptedTypes = useCallback(() => {
    const supportedFileTypes = SUPPORTED_IMAGE_EXTENSIONS.map(
      ext => `image/${ext}`,
    )
    const acceptedTypes = supportedFileTypes.join(', ')

    return acceptedTypes
  }, [])

  const onFileLoad = useCallback(() => {
    const base64 = fileReader?.result

    setIsLoading(false)

    if (base64) {
      browsedImage.current = base64
      setIsCropperModalOpen(true)
    }
  }, [fileReader?.result])

  useEffect(() => {
    fileReader.addEventListener('load', onFileLoad)

    return () => {
      fileReader.removeEventListener('load', onFileLoad)
    }
  }, [fileReader, onFileLoad])

  const onChange = useCallback(
    async e => {
      if (isLoading) return null

      setIsLoading(true)

      const browsedFile = e?.target?.files?.[0]

      try {
        if (browsedFile) {
          const compressedFile = await imageCompression(browsedFile, {
            maxSizeMB: MAX_IMAGE_COMPRESSED_SIZE_IN_MB,
          })
          const compressedFileSizeInBytes = compressedFile.size

          if (!compressedFile) {
            toast.error(t('common:errors.fileCompressionError'))

            throw new Error('File compression error')
          }
          // console.log(
          //   'browsedFile :>>',
          //   `${(browsedFile.size / 1024 / 1024).toFixed(2)} MB;`,
          //   'compressedFile :>>',
          //   `${(compressedFileSizeInBytes / 1024 / 1024).toFixed(2)} MB`,
          // )
          const fileExtension = compressedFile.type?.replace('image/', '')
          const isSupportedFileType =
            SUPPORTED_IMAGE_EXTENSIONS.includes(fileExtension)
          const maxImageSizeInBytes = maxImageSizeInMB * 1024 * 1024

          if (compressedFileSizeInBytes > maxImageSizeInBytes) {
            toast.error(
              t('common:errors.fileTooLarge', {
                maxSize: `${maxImageSizeInMB}MB`,
              }),
            )

            throw new Error('File too large')
          } else if (!isSupportedFileType) {
            const stringifiedSupportedExt =
              SUPPORTED_IMAGE_EXTENSIONS.join(', ')

            toast.error(
              t('common:errors.unsupportedFileExtension', {
                extensions: stringifiedSupportedExt,
              }),
            )

            throw new Error('File extension not supported')
          }

          fileReader.readAsDataURL(compressedFile)
        } else {
          throw new Error('File not found')
        }
      } catch (err) {
        setIsLoading(false)
      }
    },
    [fileReader, isLoading, maxImageSizeInMB, t],
  )

  const onClear = useCallback(() => {
    if (isLoading) return null

    browsedImage.current = null
    tempCroppedImage.current = null
    setSelectedImage(null)
    handleClear?.()
  }, [handleClear, isLoading])

  const isCaptureSupported = useCallback(() => {
    const inputElement = document.createElement('input')
    const isSupported = !!inputElement.capture

    return isSupported
  }, [])

  const renderPreview = useCallback(() => {
    const renderImage = () =>
      selectedImage ? (
        <StyledPreviewImage
          src={selectedImage}
          alt="preview"
          $aspectRatio={aspectRatio}
        />
      ) : (
        <img src="images/default-image.svg" width="32px" alt="placeholder" />
      )

    const renderClearButton = () =>
      selectedImage ? (
        <StyledClearButton
          type="button"
          onClick={onClear}
          title={t('common:clear')}
          disabled={isDisabled()}
        >
          <Icon name="close" width="0.85rem" color={theme.colors.white} />
        </StyledClearButton>
      ) : null

    return (
      <StyledPreview
        $width={width}
        $aspectRatio={aspectRatio}
        $selected={!!selectedImage}
      >
        {renderImage()}
        {renderClearButton()}
      </StyledPreview>
    )
  }, [
    aspectRatio,
    isDisabled,
    onClear,
    selectedImage,
    t,
    theme.colors.white,
    width,
  ])

  const renderButtons = useCallback(
    () => (
      <StyledButtonsWrapper $disabled={isDisabled()}>
        <ButtonGroup $rounded>
          <StyledLabel
            htmlFor={`${id}-browse`}
            title={t('common:browseImage')}
            $disabled={isDisabled()}
          >
            <Clickable
              as={Button}
              $large={false}
              className="no-event no-bg"
              disabled={isDisabled()}
            >
              <Icon name="browse" size="1.5rem" color={theme.colors.darkBlue} />
            </Clickable>
            <StyledPicker
              id={`${id}-browse`}
              type="file"
              accept={fileInputAcceptedTypes()}
              onChange={onChange}
              disabled={isDisabled()}
            />
          </StyledLabel>
          {isCaptureSupported() && (
            <StyledLabel
              htmlFor={`${id}-capture`}
              title={t('common:captureImage')}
              $disabled={isDisabled()}
            >
              <Clickable
                as={Button}
                $large={false}
                className="no-event no-bg"
                disabled={isDisabled()}
              >
                <Icon
                  name="camera"
                  size="1.5rem"
                  color={theme.colors.darkBlue}
                />
              </Clickable>
              <StyledPicker
                id={`${id}-capture`}
                type="file"
                accept={fileInputAcceptedTypes()}
                capture="environment"
                onChangeCapture={onChange}
                disabled={isDisabled()}
              />
            </StyledLabel>
          )}
        </ButtonGroup>
      </StyledButtonsWrapper>
    ),
    [
      isDisabled,
      id,
      t,
      theme.colors.darkBlue,
      fileInputAcceptedTypes,
      onChange,
      isCaptureSupported,
    ],
  )

  const renderCropperModal = useCallback(() => {
    const style = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        maxWidth: '480px',
        maxHeight: '50vh',
      },
      overlay: {
        zIndex: 9,
        backgroundColor: `${theme.colors.white}ee`,
      },
    }

    const cleanupImages = () => {
      browsedImage.current = null
      tempCroppedImage.current = null
    }

    const onRequestClose = () => {
      cleanupImages()
      setIsCropperModalOpen(false)
    }

    const onCrop = e => {
      const croppedBase64 = e?.target?.cropper
        ?.getCroppedCanvas?.()
        ?.toDataURL()

      tempCroppedImage.current = croppedBase64
    }

    const onCroppedImageAccepted = () => {
      if (!placeholder) {
        setSelectedImage(tempCroppedImage.current)
      }

      handleBrowse?.(tempCroppedImage.current)
      setIsCropperModalOpen(false)
      cleanupImages()
    }

    return (
      <Modal
        isOpen={isCropperModalOpen}
        onRequestClose={onRequestClose}
        style={style}
        contentLabel={t('registrationPage:step3.form.cropper.title')}
        shouldCloseOnOverlayClick={false}
        preventScroll
      >
        <Cropper
          src={browsedImage.current}
          aspectRatio={calcAspectRatio()}
          cropend={onCrop}
          ready={onCrop}
          zoomable={false}
          guides
        />
        <StyledModalButtonGroup>
          <ButtonGroup $rounded>
            <Clickable
              as={Button}
              $variant="danger"
              width="50%"
              onClick={onRequestClose}
            >
              {t('registrationPage:step3.form.cropper.cancel')}
            </Clickable>
            <Clickable
              as={Button}
              $variant="success"
              width="50%"
              onClick={onCroppedImageAccepted}
            >
              {t('registrationPage:step3.form.cropper.ok')}
            </Clickable>
          </ButtonGroup>
        </StyledModalButtonGroup>
      </Modal>
    )
  }, [
    calcAspectRatio,
    handleBrowse,
    isCropperModalOpen,
    placeholder,
    t,
    theme.colors.white,
  ])

  const renderSpinner = useCallback(
    () =>
      isDisabled() && (
        <StyledLoaderWrapper>
          <StyledLoader $size="32px" />
        </StyledLoaderWrapper>
      ),
    [isDisabled],
  )

  return (
    <StyledPickersWrapper $disabled={isDisabled()}>
      {renderPreview()}
      {renderButtons()}
      {renderCropperModal()}
      {renderSpinner()}
    </StyledPickersWrapper>
  )
}
