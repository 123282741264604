// API Docs: https://developers.google.com/maps/documentation/javascript/reference#Marker
import {useCallback, useEffect, useState} from 'react'

function Marker({onClick, onDrag, onDragEnd, ...props}) {
  const [marker, setMarker] = useState()

  const eventsMap = useCallback(
    () => ({
      click: onClick,
      drag: onDrag,
      dragend: onDragEnd,
    }),
    [onClick, onDrag, onDragEnd],
  )

  const removeMarkerListener = useCallback(
    event => {
      window.google.maps.event.clearListeners(marker, event)
    },
    [marker],
  )

  const addMarkerListener = useCallback(
    (event, handler) => {
      if (handler) {
        marker.addListener(event, (...args) => handler(...args, marker))
      }
    },
    [marker],
  )

  const resetMarkerListener = useCallback(
    (event, handler) => {
      removeMarkerListener(event)
      addMarkerListener(event, handler)
    },
    [addMarkerListener, removeMarkerListener],
  )

  useEffect(() => {
    if (marker) {
      marker.setOptions(props)

      Object.entries(eventsMap()).map(([event, handler]) => {
        resetMarkerListener(event, handler)
      })
    } else {
      setMarker(new window.google.maps.Marker())
    }

    return () => {
      if (marker) marker.setMap(null)
    }
  }, [eventsMap, marker, props, resetMarkerListener])

  return null
}

export default Marker
