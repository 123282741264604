import {StyledSvg} from './styles'

function PhoneIcon({size, color, width, height, ...props}) {
  return (
    <StyledSvg
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      $size={size}
      $width={width}
      $height={height}
      {...props}
    >
      <path
        d="M21 15.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 3.11 1h3a2 2 0 0 1 2 1.72c.127.96.361 1.903.7 2.81a2 2 0 0 1-.45 2.11L7.09 8.91a15.999 15.999 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45c.907.339 1.85.573 2.81.7A2 2 0 0 1 21 15.92Z"
        stroke={color || '#000'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSvg>
  )
}

export default PhoneIcon
