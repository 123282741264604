import styled from 'styled-components'

export const StyledPackagesWrapper = styled.fieldset`
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 22px;
  margin: 38px 0 16px 0;
  padding: 0;
  border: 0;
`
