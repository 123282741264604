import styled from 'styled-components'

export const StyledMapWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2px;
  color: ${({theme}) => theme.colors.lunarGray70};

  & > p {
    font-size: 14px;
    line-height: 1.2;
    font-style: italic;

    &.note {
      margin-top: 0.625rem;
    }
  }
`

export const StyledMapHeader = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  margin: 0 0 2px 0;

  & > p {
    font-size: 12px;
    flex-grow: 1;
    line-height: 1.3;
  }
`
