import styled, {css} from 'styled-components'

export const instructionStyles = css`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  width: 100%;
`

export const StyledInstructions1 = styled.p`
  ${instructionStyles}
  margin: 24px 0 0 0;
`

export const StyledInstructions2 = styled.p`
  ${instructionStyles}
  margin: 14px 0 0 0;
`
