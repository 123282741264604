import {css} from 'styled-components'
import colorMap from './colorMap'

const disabledStyles = css`
  background-color: ${({theme}) => theme.colors.lunarGray30};
  cursor: not-allowed;
`

const clickableStyles = () => css`
  --btn-height: ${({$large}) => ($large ? '4.5rem' : '3.125rem')};

  background-color: ${({theme, $variant}) =>
    theme[colorMap[$variant]?.backgroundColor] || 'transparent'};
  color: ${({theme, $variant}) => theme[colorMap[$variant]?.color] || 'black'};
  transition-duration: 0.1s;
  border: none;
  padding: ${({$paddingX}) => ($paddingX ? `1rem ${$paddingX}` : '1rem')};
  display: inline-flex;
  text-decoration: none;
  height: var(--btn-height);
  max-height: var(--btn-height);
  font-size: 12px;
  line-height: 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: ${({width}) => width || 'auto'};
  border: none;
  border-radius: ${({theme, $rounded, $roundedStart, $roundedEnd}) => {
    if ($rounded || ($roundedStart && $roundedEnd)) return theme.rounded1
    if ($roundedStart) return `${theme.rounded1} 0 0 ${theme.rounded1}`
    if ($roundedEnd) return `0 ${theme.rounded1} ${theme.rounded1} 0`
    return theme.rounded0
  }};

  &:hover:not([disabled]) {
    filter: brightness(0.95);
    color: ${({theme, $variant}) =>
      theme[colorMap[$variant]?.color] || 'black'};
    cursor: pointer;
  }

  &:disabled {
    ${disabledStyles}
  }

  ${({disabled}) => disabled && disabledStyles}
`

export default clickableStyles
