import {useCallback} from 'react'
import {t} from 'i18next'
import Cookies from 'js-cookie'
import {Button} from '../../../../components/Clickable'
import {
  HIDE_SPLASH_PAGE_PERMANENTLY,
  SKIPPED_SPLASH_PAGE,
} from '../../../../utils/constants'
import {
  StyledContainer,
  StyledSkip,
  StyledIllustration,
  StyledParagraph,
  StyledTextWrapper,
  StyledDontShow,
  StyledParagraphWrapper,
} from './styles'

function Splash({handleClose}) {
  const skipSplash = useCallback(() => {
    sessionStorage.setItem(
      SKIPPED_SPLASH_PAGE,
      'Skipped splash page for current session',
    )

    handleClose()
  }, [handleClose])

  const hideSplashPermanently = useCallback(() => {
    Cookies.set(HIDE_SPLASH_PAGE_PERMANENTLY, 'Hide splash page permanently', {
      sameSite: 'strict',
      secure: false,
    })

    handleClose()
  }, [handleClose])

  return (
    <StyledContainer>
      <StyledSkip as={Button} onClick={skipSplash}>
        {t('homePage:splash.skip')}
      </StyledSkip>

      <StyledIllustration
        src="images/splash-illustration.svg"
        width="512px"
        height="472px"
      />

      <StyledTextWrapper>
        <StyledParagraphWrapper>
          <StyledParagraph>{t('homePage:splash.introText1')}</StyledParagraph>
          <StyledParagraph>{t('homePage:splash.introText2')}</StyledParagraph>
        </StyledParagraphWrapper>
        <StyledDontShow as={Button} onClick={hideSplashPermanently}>
          {t('homePage:splash.dontShow')}
        </StyledDontShow>
      </StyledTextWrapper>
    </StyledContainer>
  )
}

export default Splash
