import {useCallback} from 'react'
import styled, {css} from 'styled-components'
import {
  Wrapper as MapWrapper,
  Status as MapStatus,
} from '@googlemaps/react-wrapper'
import {useTranslation} from 'react-i18next'
import GoogleMap from './GoogleMap'

const StyledContainer = styled.div`
  width: ${({$width}) => $width || '100%'};
  max-width: 100%;
  height: ${({$height}) => $height};
  border-radius: ${({theme}) => theme.rounded2};
  overflow: clip;
`
const fullContainerCss = css`
  background-color: ${({theme}) => theme.colors.lunarGray10};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledLoader = styled.div`
  color: ${({theme}) => theme.colors.black};
  ${fullContainerCss}
`

const StyledError = styled.div`
  color: ${({theme}) => theme.colors.red0};
  ${fullContainerCss}
`

function Map({width, height, apiKey, wrapperStyle = {}, ...props}) {
  const {t} = useTranslation()

  const renderMap = useCallback(
    status => {
      if (status === MapStatus.LOADING) {
        return <StyledLoader>{t('map:loading')}</StyledLoader>
      }

      if (status === MapStatus.SUCCESS) {
        return <GoogleMap width={width} height={height} {...props} />
      }

      return <StyledError>{t('map:failedToLoad')}</StyledError>
    },
    [height, props, t, width],
  )

  return (
    <StyledContainer $width={width} $height={height} style={wrapperStyle}>
      <MapWrapper
        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || apiKey}
        render={renderMap}
      />
    </StyledContainer>
  )
}

export default Map
