import styled from 'styled-components'

export const StyledPickersWrapper = styled.div`
  width: fit-content;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  filter: ${({$disabled}) => ($disabled ? 'grayscale(1)' : 'grayscale(0)')};
  opacity: ${({$disabled}) => ($disabled ? 0.4 : 1)};
  position: relative;
`

export const StyledPreviewImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: ${({theme}) => theme.rounded1};
  aspect-ratio: ${({$aspectRatio}) => $aspectRatio};
`

export const StyledPreview = styled.div`
  width: ${({$width}) => $width || '100%'};
  max-width: 100%;
  aspect-ratio: ${({$aspectRatio}) => $aspectRatio || '1 / 1'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: ${({theme}) => theme.rounded2};
  border: ${({theme, $selected}) =>
    `6px dashed ${$selected ? 'transparent' : theme.colors.lunarGray20}`};
  position: relative;
`

export const StyledClearButton = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 100vmax;
  background-color: ${({theme}) => theme.colors.red0};
  border: ${({theme}) => `4px solid ${theme.colors.white}`};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -12px;
  right: -12px;
  transition: filter 0.2s ease;

  &:hover {
    filter: brightness(1.3);
  }
`

export const StyledLabel = styled.label`
  color: ${({theme}) => theme.colors.black};
  opacity: ${({$disabled}) => ($disabled ? 0.7 : 1)};
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.7;
  }
`

export const StyledPicker = styled.input`
  background: white;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;

  &:first-child {
    border-top-left-radius: ${({theme}) => theme.rounded1};
    border-top-right-radius: ${({theme}) => theme.rounded1};
    background: red;
  }

  &:last-of-type {
    border-bottom-left-radius: ${({theme}) => theme.rounded1};
    border-bottom-right-radius: ${({theme}) => theme.rounded1};
    background: blue;
  }
`

export const StyledButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: ${({$disabled}) => ($disabled ? 0.3 : 1)};
`

export const StyledModalButtonGroup = styled.div`
  margin-top: 8px;
  position: sticky;
  bottom: 0;
`

export const StyledLoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
`
