import styled from 'styled-components'

export const StyledH2 = styled.h2`
  color: ${({theme}) => theme.colors.black};
  font-size: 16px;
  text-align: left;
  width: 100%;
  margin: 24px 0 0 0;
`

export const StyledList = styled.ol`
  color: ${({theme}) => theme.colors.black};
  font-size: 14px;
  margin: 8px 0 0 0;
`

export const StyledListItem = styled.li`
  color: ${({theme}) => theme.colors.black};
`
