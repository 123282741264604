import {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import styled, {css} from 'styled-components'
import {ellipsisStyles} from '../../styles/common'

const StyledOperatingSchedule = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.25rem;
  background-color: ${({theme}) => theme.colors.lunarGray0};
  border-radius: ${({theme}) => theme.rounded1};
  padding: 1.25rem 0.75rem;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.05);
`

const todayStyles = css`
  &:after {
    content: '';
    display: inline-block;
    background-color: ${({theme}) => theme.colors.green};
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 0.25rem;
  }
`

const StyledDay = styled.time`
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 0.875rem;
  display: inline-flex;
  gap: 0.65rem;
  flex-direction: row;
  align-items: baseline;

  ${({$isToday}) => $isToday && todayStyles}
`

const StyledTimeContainer = styled.p`
  margin: 0;
  font-weight: 300;
  line-height: 1;

  ${ellipsisStyles}
`

const StyledSchedule = styled.time`
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 0.875rem;

  ${ellipsisStyles}
`

function OperatingSchedule({
  day,
  startTime,
  endTime,
  isToday,
  isOpened,
  allDay,
  isOpened247,
}) {
  const {t} = useTranslation()

  const renderTime = useCallback(() => {
    const validTimes = [startTime, endTime].filter(Boolean)
    const finalChildren = []

    validTimes.map((time, index) => {
      finalChildren.push(
        // eslint-disable-next-line react/no-array-index-key
        <StyledSchedule key={index.toString()} dateTime={time}>
          {time}
        </StyledSchedule>,
      )

      if (index < validTimes.length - 1) {
        finalChildren.push(' - ')
      }
    })

    return finalChildren
  }, [endTime, startTime])

  const renderOpened247 = useCallback(
    () => <StyledSchedule>{t('officePage:isOpened247')}</StyledSchedule>,
    [t],
  )

  const renderOpenedAllDay = useCallback(
    () => <StyledSchedule>{t('officePage:isOpenedAllDay')}</StyledSchedule>,

    [t],
  )

  const renderClosed = useCallback(
    () => <StyledSchedule>{t('officePage:isClosed')}</StyledSchedule>,
    [t],
  )

  const renderSchedule = useCallback(() => {
    if (isOpened247) return renderOpened247()

    if (!isOpened) return renderClosed()

    if (allDay) return renderOpenedAllDay()

    return renderTime()
  }, [
    allDay,
    isOpened,
    isOpened247,
    renderClosed,
    renderOpened247,
    renderOpenedAllDay,
    renderTime,
  ])

  return (
    <StyledOperatingSchedule>
      <StyledDay dateTime={day} $isToday={isToday}>
        {day}
      </StyledDay>
      <StyledTimeContainer>{renderSchedule()}</StyledTimeContainer>
    </StyledOperatingSchedule>
  )
}

export default OperatingSchedule
