import countries from 'i18n-iso-countries'
import enCountryList from 'i18n-iso-countries/langs/en.json'
import frCountryList from 'i18n-iso-countries/langs/fr.json'

countries.registerLocale(enCountryList)
countries.registerLocale(frCountryList)

export function sanitizePhoneNumbers(phoneNumbers) {
  const truthyNums = (phoneNumbers || []).filter(Boolean)
  const deDuplicatedNums = [...new Set(truthyNums)]

  return deDuplicatedNums
}

export function retrieveCompanyAddress(address, language) {
  const countryName = address?.country
    ? countries.getName(address.country, language)
    : null
  const addressParts = [
    address?.street,
    address?.town_village,
    countryName,
  ].filter(Boolean)
  const fullAddress = addressParts.join(', ')

  return fullAddress
}

export function retrieveGoogleMapsUrl(address) {
  const lat = address?.lat
  const lng = address?.lng
  const isAddressValid = lat && lng
  const mapsUrl = isAddressValid
    ? `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
    : null

  return mapsUrl
}

export function requiredFieldLabel(label) {
  const requiredLabel = `${label} *`

  return requiredLabel
}

export function getCurrentPosition({
  showErrorOnInitialLocationFetch = false,
  initialLocationFetched,
  onSuccess,
  onError,
}) {
  const geolocationSupported = navigator?.geolocation?.getCurrentPosition

  if (!geolocationSupported) onError?.()

  navigator.geolocation.getCurrentPosition(
    position => {
      const lat = position?.coords?.latitude
      const lng = position?.coords?.longitude

      onSuccess?.({lat, lng})
    },
    e => {
      if (!showErrorOnInitialLocationFetch && !initialLocationFetched) return

      onError?.(e)
    },
  )
}

export function parseMathCalc(str) {
  // eslint-disable-next-line no-new-func
  const execCalc = Function(`'use strict'; return (${str})`)

  return execCalc()
}

export function blobToBase64(blob) {
  const reader = new FileReader()
  reader.readAsDataURL(blob)

  return new Promise(resolve => {
    reader.onloadend = () => {
      const base64 = reader.result

      resolve(base64)
    }
  })
}

export function uuid() {
  function rand() {
    // eslint-disable-next-line no-bitwise
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  }

  const uniqueId = `${rand() + rand()}-${rand()}-3${rand().substring(
    0,
    2,
  )}-${rand()}-${rand()}${rand()}${rand()}`.toLowerCase()

  return uniqueId
}

export async function setCustomFormikFieldValue({
  name,
  value,
  validate = true,
  setFieldValue,
  setFieldTouched,
}) {
  await setFieldValue(name, value, validate)
  setFieldTouched(name, true)
}

export function isUsingUserAgent(userAgent) {
  const isUserAgentInUse = navigator.userAgent.indexOf(userAgent) > -1

  return isUserAgentInUse
}

export function isUsingChrome() {
  return isUsingUserAgent('Chrome')
}

export function isUsingFirefox() {
  return isUsingUserAgent('Firefox')
}

export function isUsingEdge() {
  return isUsingUserAgent('Edg')
}

export function isUsingSafari() {
  return isUsingUserAgent('Safari')
}

export function isUsingSamsungInternet() {
  return isUsingUserAgent('Samsung')
}
