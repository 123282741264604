import styled from 'styled-components'
import Clickable from '../../../components/Clickable'
import {waveBackgroundStyles} from '../../../styles/common'

const StyledContainer = styled.div`
  background-color: ${({theme}) => theme.colors.white};
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: clip;

  ${waveBackgroundStyles}
`

const StyledMain = styled.main`
  width: 100%;
  padding: 1rem;
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  > .the-futur-logo {
    padding: 2rem 0 6rem 0;
  }
`

const StyledLink = styled(Clickable)`
  color: ${({theme}) => theme.colors.black};
  font-size: 0.75rem;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  margin-top: 0.75rem;
  // padding: 1rem; Removed for tests

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`

const StyledFooter = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.5rem;
  padding: 1rem;
  padding-bottom: 2rem;

  > * {
    flex: 1 0 0;
  }
`

export {StyledContainer, StyledMain, StyledLink, StyledFooter}
