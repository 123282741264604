import {useCallback} from 'react'
import styled, {css, useTheme} from 'styled-components'
import BaseSelect from 'react-select'
import AsyncSelect from 'react-select/async'
import CreatableSelect from 'react-select/creatable'
import AsyncCreatableSelect from 'react-select/async-creatable'
import {Formik, Form} from 'formik'
import {useTranslation} from 'react-i18next'
import Button from '../Clickable/Button'
import Clickable from '../Clickable'
import Icon from '../Icon'
import {setCustomFormikFieldValue} from '../../utils/functions'

const StyledForm = styled(Form)`
  width: 100%;
  max-width: 100%;
`

const StyledSearch = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  gap: 1px;
  width: ${({width}) => width ?? '100%'};
  max-width: 100%;
  height: 3.125rem;
  border-radius: ${({theme}) => theme.rounded1};
  background-color: ${({theme}) => theme.colors.lunarGray10};
`

const selectStyles = css`
  height: 100%;
  flex: 1 0 0;
  font-size: 1rem;
  max-width: ${({$totalItems}) => `${(100 / $totalItems).toFixed(2)}%`};
  min-width: 0;
  background-color: ${({theme, $lighterBg}) =>
    theme.colors[$lighterBg ? 'lunarGray10' : 'lunarGray20']};

  &:first-child {
    border-top-left-radius: ${({theme}) => theme.rounded1};
    border-bottom-left-radius: ${({theme}) => theme.rounded1};
  }

  &:last-child {
    border-top-right-radius: ${({theme, $showButton}) =>
      $showButton ? 0 : theme.rounded1};
    border-bottom-right-radius: ${({theme, $showButton}) =>
      $showButton ? 0 : theme.rounded1};
  }

  & div[class$='control'] {
    height: 100%;
    border: none;
    background-color: transparent;

    & div[class$='placeholder'] {
      text-align: center;
      color: ${({theme}) => theme.colors.lunarGray50};
      font-size: 0.875rem;
    }
  }
`

const StyledBaseSelect = styled(BaseSelect)`
  ${selectStyles}
`

const StyledAsyncSelect = styled(AsyncSelect)`
  ${selectStyles}
`

const StyledCreatableSelect = styled(CreatableSelect)`
  ${selectStyles}
`

const StyledAsyncCreatableSelect = styled(AsyncCreatableSelect)`
  ${selectStyles}
`

StyledBaseSelect.displayName = 'StyledBaseSelect'
StyledAsyncSelect.displayName = 'StyledAsyncSelect'
StyledCreatableSelect.displayName = 'StyledCreatableSelect'
StyledAsyncCreatableSelect.displayName = 'StyledAsyncCreatableSelect'

function Search({
  items,
  validationSchema,
  onSubmit = () => undefined,
  $showButton,
  className,
}) {
  const theme = useTheme()
  const {t} = useTranslation()

  const StyledSelect = useCallback(({async, creatable}) => {
    let StyledSelectComponent

    if (creatable) {
      StyledSelectComponent = async
        ? StyledAsyncCreatableSelect
        : StyledCreatableSelect
    } else {
      StyledSelectComponent = async ? StyledAsyncSelect : StyledBaseSelect
    }

    return StyledSelectComponent
  }, [])

  const initialValues = useCallback(
    () =>
      items.reduce(
        (acc, item) => ({
          ...acc,
          [item.name]: item?.defaultValue || null,
        }),
        {},
      ),
    [items],
  )

  const renderForm = useCallback(
    ({
      isValid,
      handleSubmit,
      submitForm,
      isSubmitting,
      setFieldValue,
      setFieldTouched,
    }) => {
      const disabled = isSubmitting || !isValid

      function handleSelectChange(name) {
        return async function (option, {action}) {
          await setCustomFormikFieldValue({
            name,
            value: option,
            setFieldValue,
            setFieldTouched,
          })

          if (!$showButton) {
            submitForm()
          }

          if (action === 'clear') {
            const currentItem = items.find(item => item.name === name)

            if (!currentItem) return null

            currentItem.onClear?.()
          }

          return null
        }
      }

      const renderFields = () =>
        items?.map(item => {
          const StyledSelectComponent = StyledSelect(item)
          const noOptionsMessage = () => t('common:search.noOptionsMessage')
          const formatCreateLabel = value =>
            t('common:search.createLabel', {value})

          return (
            <StyledSelectComponent
              key={item.key}
              isClearable
              components={{
                DropdownIndicator: null,
              }}
              onChange={handleSelectChange(item.name)}
              noOptionsMessage={noOptionsMessage}
              formatCreateLabel={formatCreateLabel}
              {...item}
              $totalItems={items?.length || 0}
            />
          )
        })

      const renderButton = () =>
        !!$showButton && (
          <Clickable
            as={Button}
            type="submit"
            $variant="navy"
            width="3.75rem"
            disabled={disabled}
            $roundedEnd
          >
            <Icon
              name="search"
              size="1.5em"
              color={theme.colors[disabled ? 'lunarGray20' : 'white']}
            />
          </Clickable>
        )

      return (
        <StyledForm
          onSubmit={disabled ? null : handleSubmit}
          className={className}
        >
          <StyledSearch>
            {renderFields()}
            {renderButton()}
          </StyledSearch>
        </StyledForm>
      )
    },
    [$showButton, StyledSelect, className, items, t, theme.colors],
  )

  return (
    <Formik
      initialValues={initialValues()}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnMount
    >
      {renderForm}
    </Formik>
  )
}

export default Search
