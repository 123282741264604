import styled from 'styled-components'

export const FieldsWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 24px 0 0 0;
`

export const StyledFieldSetFieldsWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const StyledFieldSetHRule = styled.hr`
  width: 100%;
  margin: 8px 0;
  border-color: ${({theme}) => theme.colors.lunarGray0};
  opacity: 50%;
`

export const StyledSocialLinkWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
`

export const StyledSocialIconWrapper = styled.div`
  height: 36px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const StyledSocialLinkTextField = styled.div`
  flex-grow: 1;
`
