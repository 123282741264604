import {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {toast} from 'react-toastify'
import {
  isUsingChrome,
  isUsingEdge,
  isUsingFirefox,
  isUsingSafari,
  isUsingSamsungInternet,
} from '../../utils/functions'

export default function Initializer({children}) {
  const {t} = useTranslation()
  const [
    incompatibleBrowserWarningDisplayed,
    setIncompatibleBrowserWarningDisplayed,
  ] = useState(false)

  const isBrowserCompatible = useCallback(() => {
    const isUsingACompatibleBrowser =
      (isUsingChrome() ||
        isUsingFirefox() ||
        isUsingEdge() ||
        isUsingSafari()) &&
      !isUsingSamsungInternet()

    return isUsingACompatibleBrowser
  }, [])

  const notifyBrowserIncompatibility = useCallback(() => {
    if (!incompatibleBrowserWarningDisplayed) {
      setIncompatibleBrowserWarningDisplayed(true)

      if (!isBrowserCompatible()) {
        const toastOpts = {
          autoClose: false,
          position: 'bottom-center',
        }

        toast.warn(t('common:warnings.incompatibleBrowser'), toastOpts)
      }
    }
  }, [incompatibleBrowserWarningDisplayed, isBrowserCompatible, t])

  useEffect(() => {
    notifyBrowserIncompatibility()
  }, [notifyBrowserIncompatibility])

  return children
}
