import {StyledSvg} from './styles'

function EnvelopeUploadIcon({size, color, width, height, ...props}) {
  return (
    <StyledSvg
      viewBox="0 0 25 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      $size={size}
      $width={width}
      $height={height}
      {...props}
    >
      <path
        d="M22.063 14.25a1.125 1.125 0 0 0-1.125 1.125v4.5A1.125 1.125 0 0 1 19.813 21H4.063a1.125 1.125 0 0 1-1.125-1.125V9.086l6.614 6.627a3.375 3.375 0 0 0 4.77 0l1.846-1.845a1.13 1.13 0 0 0-1.598-1.598l-1.845 1.845a1.125 1.125 0 0 1-1.575 0L4.524 7.5h7.413a1.125 1.125 0 1 0 0-2.25H4.064A3.375 3.375 0 0 0 .688 8.625v11.25a3.375 3.375 0 0 0 3.375 3.375h15.75a3.375 3.375 0 0 0 3.375-3.375v-4.5a1.125 1.125 0 0 0-1.125-1.125Zm1.923-9.799-3.375-3.375A1.124 1.124 0 0 0 20.24.84a1.125 1.125 0 0 0-.855 0 1.124 1.124 0 0 0-.371.236l-3.375 3.375a1.13 1.13 0 1 0 1.597 1.598l1.451-1.463v6.289a1.125 1.125 0 1 0 2.25 0V4.586L22.39 6.05a1.124 1.124 0 0 0 1.597 0 1.124 1.124 0 0 0 0-1.598Z"
        fill={color || '#000'}
      />
    </StyledSvg>
  )
}

export default EnvelopeUploadIcon
