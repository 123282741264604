import {StyledSvg} from './styles'

function CloseIcon({size, color, width, height, ...props}) {
  return (
    <StyledSvg
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      $size={size}
      $width={width}
      $height={height}
      {...props}
    >
      <path
        d="m12.88 11 8.4-8.387A1.339 1.339 0 1 0 19.387.72L11 9.12 2.613.72A1.339 1.339 0 0 0 .72 2.613L9.12 11l-8.4 8.387a1.333 1.333 0 0 0 0 1.893 1.333 1.333 0 0 0 1.893 0L11 12.88l8.387 8.4a1.332 1.332 0 0 0 1.893 0 1.332 1.332 0 0 0 0-1.893L12.88 11Z"
        fill={color || '#000'}
      />
    </StyledSvg>
  )
}

export default CloseIcon
