import styled, {keyframes} from 'styled-components'
import Clickable from '../../../components/Clickable'

const cascadeIn = keyframes`
  0% {
    left: -15px;
    opacity: 0;
  }
  15% {
    left: -10px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
`

export const OfficeDescription = styled.p`
  font-size: 12px;
  line-height: 14px;
  width: 100%;
`

export const OfficeCarouselWrapper = styled.div`
  margin: 26px 0;
  max-width: 100%;
  width: 100%;
`

export const OfficeHeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const OfficeWrapper = styled.div`
  flex-grow: 1;
  width: 100%;
  max-width: 100%;
`

export const OfficeHeaderWrapper = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
`

export const OfficeSocialWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 14px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 20px 0;
`

export const OfficeHoursWrapper = styled.details`
  width: 100%;
`

export const OfficeHoursText = styled.summary`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  list-style: none;
  position: relative;
  display: flex;
  margin: 26px 0 20px 0;

  &:after {
    content: '►';
    position: relative;
    margin-left: 0.5rem;
    transform: rotate(0);
    transition: 0.25s transform ease;

    details[open] & {
      transform: rotate(90deg);
    }
  }

  &::-webkit-details-marker {
    display: none;
  }
`

export const OfficeBtnWrapper = styled.div`
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: ${({theme}) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 2rem;
  box-shadow: ${({theme}) =>
    `16px 0 ${theme.colors.white}, -16px 0 ${theme.colors.white}`};
`

export const OfficeOperatingScheduleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 1rem;
`
export const OfficePublicHolidaysOperatingSchedule = styled.p`
  margin: 1rem 0 1rem 0.75rem;
  font-style: italic;
  font-size: 14px;
`

export const OfficePlaceholderWrapper = styled.div`
  width: 60px;
  height: 60px;
  min-width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.colors.lunarGray10};
  border-radius: ${({theme}) => theme.rounded1};
`

export const OfficeCompanyLogoWrapper = styled.img`
  width: 60px;
  height: 60px;
  min-width: 60px;
  border-radius: ${({theme}) => theme.rounded1};
`
export const OfficeStyledClickable = styled(Clickable)`
  padding: 0;
  position: relative;
  animation: ${cascadeIn} 2.5s ease-in-out;
`
