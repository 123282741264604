import {useCallback, useEffect, useRef} from 'react'

function useDebounce(callback, wait = 500) {
  const timeoutId = useRef(null)

  const clearDebounceTimeout = useCallback(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
      timeoutId.current = null
    }
  }, [])

  const debounce = useCallback(
    (...args) =>
      new Promise((resolve, reject) => {
        clearDebounceTimeout()

        timeoutId.current = setTimeout(
          () =>
            callback(...args)
              .then(res => resolve(res))
              .catch(e => reject(e)),
          wait,
        )
      }),
    [callback, clearDebounceTimeout, wait],
  )

  useEffect(() => clearDebounceTimeout, [clearDebounceTimeout])

  return debounce
}

export default useDebounce
