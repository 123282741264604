import {useCallback} from 'react'
import styled from 'styled-components'
import {Pagination} from 'swiper'
// eslint-disable-next-line import/no-unresolved
import {Swiper, SwiperSlide} from 'swiper/react'
// eslint-disable-next-line import/no-unresolved
import 'swiper/css'
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/pagination'

const StyledSwiper = styled(Swiper)`
  width: ${({$width}) => $width || '100%'};
  height: ${({$height}) => $height || '100%'};
  aspect-ratio: ${({$aspectRatio}) => $aspectRatio || 'unset'};
  max-width: 100%;
  border-radius: ${({theme}) => theme.rounded3};
`

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: ${({theme}) => theme.rounded3};
  background-color: ${({theme}) => theme.colors.lunarGray10};
`

function ImageCarousel({
  width,
  height,
  aspectRatio,
  items = [],
  pagination = true,
  ...props
}) {
  const modules = useCallback(() => {
    const finalModules = []

    if (pagination) finalModules.push(Pagination)

    return finalModules
  }, [pagination])

  const renderSlides = useCallback(
    () =>
      items.map(item => (
        <SwiperSlide key={item.image_id}>
          <StyledImage
            // src={`${process.env.REACT_APP_API_URL}${item?.filename}`}
            src={item.img || ''}
            // alt={item?.filename}
          />
        </SwiperSlide>
      )),
    [items],
  )

  return (
    <StyledSwiper
      spaceBetween={50}
      slidesPerView={1}
      pagination={pagination}
      modules={modules()}
      $width={width}
      $height={height}
      $aspectRatio={aspectRatio}
      {...props}
    >
      {renderSlides()}
    </StyledSwiper>
  )
}

export default ImageCarousel
