import styled from 'styled-components'

export const StyledDayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 4px 0;
`

export const StyledDay = styled.p`
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 4px 0;
  color: ${({theme, $disabled}) =>
    theme.colors[$disabled ? 'lunarGray30' : 'black']};
`

export const StyledTimeFields = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`

export const StyledCheckboxFields = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0 8px;
`
