import {useCallback, useEffect, useMemo, /* useRef, */ useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSearchParams, Link} from 'react-router-dom'
import {object} from 'yup'
// eslint-disable-next-line import/no-unresolved
// import {Swiper, SwiperSlide} from 'swiper/react'
// import {FreeMode, Mousewheel} from 'swiper'
import {toast} from 'react-toastify'
import useDebounce from '../../utils/hooks/useDebounce'
import {
  getLocationSuggestions,
  getOffices,
  // getPopularSuggestions,
} from '../../services/api'
// import SuggestionCard from '../../components/SuggestionCard'
import Search from '../../components/Search'
import SearchCard from '../../components/SearchCard'
import DefaultLayout from '../../layouts/DefaultLayout'
import {PageHeading1, StyledSearchContainer} from '../../styles/common'
import {
  // PopularSuggestionText,
  // PopularSuggestionWrapper,
  // SuggestionCardWrapper,
  OfficesListingContainer,
  OfficesNoResult,
} from './styles'
import {StyledLink} from '../CategoriesPage/styles'
import {retrieveCompanyAddress} from '../../utils/functions'
// import {ORION_ADDRESS_LAT, ORION_ADDRESS_LNG} from '../../utils/constants'
// eslint-disable-next-line import/no-unresolved
// import 'swiper/css'
// eslint-disable-next-line import/no-unresolved
// import 'swiper/css/free-mode'
// eslint-disable-next-line import/no-unresolved
// import 'swiper/css/scrollbar'

function OfficesPage() {
  const {t, i18n} = useTranslation()
  const [isLoading, setIsLoading] = useState(true)
  const [offices, setOffices] = useState([])
  const [searchParams, setSearchParams] = useSearchParams()
  const [defaultSearchValue, setDefaultSearchValue] = useState(null)
  // const [popularSuggestions, setPopularSuggestions] = useState([])
  // const initializedPositionRef = useRef(false)
  // const latLng = useRef({
  //   lat: ORION_ADDRESS_LAT,
  //   lng: ORION_ADDRESS_LNG,
  // })

  // const fetchPopularSuggestions = useCallback(() => {
  //   getPopularSuggestions(latLng.current.lat, latLng.current.lng)
  //     .then(setPopularSuggestions)
  //     .catch(e => {
  //       console.log('e :>> ', e)
  //     })
  // }, [])

  // useEffect(() => {
  //   if (
  //     !initializedPositionRef.current &&
  //     navigator?.geolocation?.getCurrentPosition
  //   ) {
  //     navigator.geolocation.getCurrentPosition(position => {
  //       initializedPositionRef.current = true

  //       const newLat = position?.coords?.latitude
  //       const newLng = position?.coords?.longitude

  //       latLng.current = {
  //         lat: newLat,
  //         lng: newLng,
  //       }

  //       fetchPopularSuggestions()
  //     }, fetchPopularSuggestions)
  //   }
  // }, [fetchPopularSuggestions])

  const retrieveCustomSearchValue = useCallback(() => {
    const where = searchParams.get('where')

    const newDefaultSearchVal = {
      value: where,
      label: where,
      __isNew__: true,
    }

    return newDefaultSearchVal
  }, [searchParams])

  useEffect(() => {
    let setState = ({newDefaultSearchVal, newOffices}) => {
      setDefaultSearchValue(newDefaultSearchVal)
      setOffices(newOffices)
      setIsLoading(false)
    }

    ;(async () => {
      let newDefaultSearchVal = null
      const what = searchParams.get('what')
      const where = searchParams.get('where')

      if (where) {
        await getLocationSuggestions(where)
          .then(res => {
            const noSuggestions = (res || [])?.length === 0
            const customLocation = where !== res?.[0]?.label

            if (noSuggestions || customLocation) {
              newDefaultSearchVal = retrieveCustomSearchValue()
            } else {
              newDefaultSearchVal = {
                value: res[0]?._id,
                label: res[0]?.label,
              }
            }
          })
          .catch(e => {
            console.log('e :>> ', e)
            newDefaultSearchVal = retrieveCustomSearchValue()
          })
      }

      getOffices(what, where)
        .then(newOffices => {
          setState?.({
            newDefaultSearchVal,
            newOffices,
          })
        })
        .catch(e => {
          console.log('e :>> ', e)
          toast.error(t('common:errors.unknownRefresh'))
        })
    })()

    return () => {
      setState = null
    }
  }, [retrieveCustomSearchValue, searchParams, t])

  const locationSuggestions = useDebounce(
    inputValue =>
      new Promise((resolve, reject) => {
        getLocationSuggestions(inputValue)
          .then(res => {
            const sanitizedRes = res.map(item => ({
              value: item?._id,
              label: item?.label,
            }))

            resolve(sanitizedRes)
          })
          .catch(e => {
            console.log('e :>> ', e)
            reject(e)
          })
      }),
    500,
  )

  // const renderPopularSuggestions = useCallback(() => {
  //   const what = searchParams.get('what')
  //   const atleastOneSuggestion = popularSuggestions.length > 0
  //   const showSuggestions = !!(atleastOneSuggestion && what)

  //   return (
  //     showSuggestions && (
  //       <PopularSuggestionWrapper>
  //         <PopularSuggestionText>
  //           {t('officesPage:popularSuggestions')}
  //         </PopularSuggestionText>
  //         <SuggestionCardWrapper>
  //           <Swiper
  //             direction="horizontal"
  //             slidesPerView="auto"
  //             spaceBetween={12}
  //             freeMode
  //             mousewheel
  //             modules={[FreeMode, Mousewheel]}
  //           >
  //             {popularSuggestions.map(suggestion => (
  //               <SwiperSlide
  //                 key={suggestion._id}
  //                 className="popular-suggestion-slide"
  //               >
  //                 <SuggestionCard text={suggestion.label} />
  //               </SwiperSlide>
  //             ))}
  //           </Swiper>
  //         </SuggestionCardWrapper>
  //       </PopularSuggestionWrapper>
  //     )
  //   )
  // }, [popularSuggestions, searchParams, t])

  const renderOfficesListing = useCallback(() => {
    const retrieveFullAddress = address =>
      retrieveCompanyAddress(address, i18n.language)

    return offices.map(office => (
      <StyledLink
        as={Link}
        key={office._id}
        to={{
          pathname: `/offices/${office._id}`,
        }}
      >
        <SearchCard
          address={office?.address}
          fullAddress={retrieveFullAddress(office?.address)}
          phones={office?.phoneNumbers}
          title={office?.company_name || ''}
          image={office?.logo}
          includeKey={office?.package?.include}
        />
      </StyledLink>
    ))
  }, [i18n.language, offices])

  const renderNoOfficeText = useCallback(
    () => <OfficesNoResult>{t('officesPage:noResult')}</OfficesNoResult>,
    [t],
  )

  const renderPageHeading = useCallback(() => {
    const what = searchParams.get('what')

    return (
      <PageHeading1>
        {what ? `${what}` : t('officesPage:companies')}{' '}
        {t('officesPage:heading1')}
      </PageHeading1>
    )
  }, [searchParams, t])

  const renderOffices = useCallback(() => {
    const hasOffice =
      offices.length > 0 ? renderOfficesListing() : renderNoOfficeText()
    return hasOffice
  }, [offices.length, renderNoOfficeText, renderOfficesListing])

  const handleSubmit = useCallback(
    (values, formikBag) => {
      const setSubmitting = formikBag?.setSubmitting
      const what = searchParams.get('what')
      const newSearchParams = {}

      setSubmitting?.(false)

      if (what) {
        newSearchParams.what = what
      }
      if (values?.location?.label) {
        newSearchParams.where = values.location.label
      }

      setSearchParams(newSearchParams)
    },
    [searchParams, setSearchParams],
  )

  const validationSchema = useMemo(
    () => object({location: object().nullable()}),
    [],
  )

  const searchFields = useMemo(
    () => [
      {
        key: 'location',
        name: 'location',
        defaultOptions: [],
        placeholder: t('officesPage:search.placeholder'),
        loadOptions: locationSuggestions,
        onClear: handleSubmit,
        async: true,
        creatable: true,
        defaultValue: defaultSearchValue,
      },
    ],
    [defaultSearchValue, handleSubmit, locationSuggestions, t],
  )

  return (
    <DefaultLayout loading={isLoading}>
      {renderPageHeading()}
      <StyledSearchContainer>
        <Search
          validationSchema={validationSchema}
          items={searchFields}
          onSubmit={handleSubmit}
          $showButton
        />
      </StyledSearchContainer>
      {/* {renderPopularSuggestions()} */}
      <OfficesListingContainer>{renderOffices()}</OfficesListingContainer>
    </DefaultLayout>
  )
}

export default OfficesPage
