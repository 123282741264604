import {useCallback} from 'react'
import Icon from '../Icon'
import {CardImage, CardTitle, CardWrapper, ImageWrapper} from './styles'

function Card({image, alt, title, textOnly}) {
  const renderMiniCard = useCallback(
    () => (
      <CardWrapper $mini>
        <CardTitle>{title}</CardTitle>
      </CardWrapper>
    ),
    [title],
  )

  const renderFullCard = useCallback(
    () => (
      <CardWrapper>
        {image ? (
          <CardImage src={image} alt={alt} width={115} height={150} />
        ) : (
          <ImageWrapper>
            <Icon name="theFutur" size="1.875rem" />
          </ImageWrapper>
        )}
        <CardTitle>{title}</CardTitle>
      </CardWrapper>
    ),
    [alt, image, title],
  )

  const renderCard = useCallback(
    () => (textOnly ? renderMiniCard() : renderFullCard()),
    [renderFullCard, renderMiniCard, textOnly],
  )

  return renderCard()
}

export default Card
