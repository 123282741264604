import {useCallback} from 'react'
import styled from 'styled-components'

const StyledButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 0;
  width: ${({$width}) => $width ?? 'unset'};
`

function ButtonGroup({$rounded, $roundedStart, $roundedEnd, width, children}) {
  const sanitizedChildren = useCallback(
    () => children?.filter(Boolean) || [],
    [children],
  )

  const borderRoundedStart = useCallback(
    index => !!(index === 0 && ($rounded || $roundedStart)),
    [$rounded, $roundedStart],
  )

  const borderRoundedEnd = useCallback(
    index =>
      !!(index === sanitizedChildren().length - 1 && ($rounded || $roundedEnd)),
    [sanitizedChildren, $rounded, $roundedEnd],
  )

  const buttons = useCallback(
    () =>
      sanitizedChildren().map((child, index) => ({
        ...child,
        props: {
          ...child.props,
          $roundedStart: borderRoundedStart(index),
          $roundedEnd: borderRoundedEnd(index),
        },
      })),
    [sanitizedChildren, borderRoundedStart, borderRoundedEnd],
  )

  return <StyledButtonGroup $width={width}>{buttons()}</StyledButtonGroup>
}

export default ButtonGroup
