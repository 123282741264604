import styled from 'styled-components'
import Clickable from '../../../../../components/Clickable'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  min-height: 100vh;
`

const StyledSkip = styled(Clickable)`
  text-align: right;
  font-size: 12px;
  line-height: 14px;
  color: ${({theme}) => theme.colors.black};
  border: none;
  background: transparent;
  align-self: flex-end;
  padding: 1rem;
  cursor: pointer;
`
const StyledDontShow = styled(Clickable)`
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: ${({theme}) => theme.colors.black};
  border: none;
  background: transparent;
  padding: 1rem;
  cursor: pointer;
`
const StyledIllustration = styled.img`
  width: 100%;
  height: auto;
`

const StyledTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 52px;
  margin: 0 40px;
`
const StyledParagraph = styled.p`
  font-size: 12px;
  line-height: 14px;
  text-align: center;
`

const StyledParagraphWrapper = styled.div``

export {
  StyledContainer,
  StyledSkip,
  StyledIllustration,
  StyledParagraph,
  StyledTextWrapper,
  StyledDontShow,
  StyledParagraphWrapper,
}
