import styled from 'styled-components'

export const PackageCardWrapper = styled.label`
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: start;
  background-color: ${({$backgroundColor}) => $backgroundColor};
  border-radius: ${({theme}) => theme.rounded1};
  padding: 12px 18px;
  width: 100%;
  color: ${({theme}) => theme.colors.white};
  outline: ${({$selected, theme}) =>
    $selected ? `4px solid ${theme.colors.darkBlue}` : 'none'};
  outline-offset: 1px;
  cursor: pointer;
  transition: filter 0.2s ease-in-out;

  &:hover {
    filter: brightness(0.9);
  }

  &:focus-within {
    outline: ${({$selected, theme}) =>
      `4px solid ${theme.colors[$selected ? 'darkBlue' : 'blue0']}`};
    outline-offset: 1px;
  }

  input[type='radio'] {
    opacity: 0;
    margin: 0;
    position: absolute;
  }

  p {
    margin: 0;
  }
`

export const PackageCardPackage = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
`

export const PackageCardPrice = styled.p`
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
`

export const PackageCardTextWrapper = styled.ul`
  margin: 0;
  padding: 0 0 0 12px;
`

export const PackageCardText = styled.li`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
`

export const PackageCardChoose = styled.p`
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align right;
`

export const PackageCardGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
  margin-bottom: 22px;
`
