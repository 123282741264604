import Back from '../../components/Back'
import {StyledLoader} from '../../styles/common'
import {StyledContainer, StyledLoaderWrapper, StyledMain} from './styles'

export default function DefaultLayout({loading, noBottomPadding, children}) {
  return (
    <StyledContainer>
      <Back />
      <StyledMain $noBottomPadding={noBottomPadding}>
        {loading ? (
          <StyledLoaderWrapper>
            <StyledLoader />
          </StyledLoaderWrapper>
        ) : (
          children
        )}
      </StyledMain>
    </StyledContainer>
  )
}
