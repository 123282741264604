import {Form} from 'formik'
import styled from 'styled-components'
import {headingStyles} from '../../../styles/common'

export const StyledForm = styled(Form)`
  width: 100%;
  max-width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

export const MainContentWrapper = styled.div`
  flex-grow: 1;
  width: 100%;
  max-width: 100%;
`

export const StyledGreetings = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  width: 100%;

  .greetings__title {
    font-size: 1.5rem;
    ${headingStyles}
  }
`

export const StyledLoaderWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  padding: 4rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const PageHeadingWrapper = styled.div`
  margin: 40px 0 0 0;
  width: 100%;
`

export const StepDescription = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  margin: 26px 0 0 0;
  width: 100%;
`

export const StyledFieldSet = styled.fieldset`
  width: 100%;
  max-width: 100%;
  margin: 24px 0 0 0;
  border: ${({theme}) => `1px solid ${theme.colors.lunarGray40}`};
  border-radius: ${({theme}) => theme.rounded1};
  position: relative;
`

export const StyledLegendWrapper = styled.div`
  & legend {
    font-size: 16px;
    color: ${({theme}) => theme.colors.black};
    padding: 0;
    margin: 16px 0;
  }
`
