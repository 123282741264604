import styled from 'styled-components'

export const StyledWrapper = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
`

export const StyledPaymentProviders = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 28px;
  flex-wrap: wrap;
  margin-top: 1.5rem;
`

export const StyledParagraph = styled.p`
  font-size: 0.75rem;
  color: ${({theme}) => theme.colors.black};
  width: 100%;
  max-width: 100%;

  &:nth-child(2) {
    margin: 0.75rem 0 1.25rem 0;
  }
`

export const StyledSectionHeading = styled.h2`
  width: 100%;
  max-width: 100%;
  color: ${({theme}) => theme.colors.lunarGray30};
  font-size: 0.875rem;
  font-weight: bold;
  margin: 0.5rem 0 0 0;
`

export const StyledBusinessName = styled.p`
  color: ${({theme}) => theme.colors.lunarGray60};
  font-size: 0.875rem;
  font-weight: bold;
  margin-top: 0.5rem;
`

export const StyledTable = styled.table`
  margin: 0.5rem 0 1rem 0;
  color: ${({theme}) => theme.colors.lunarGray60};
`

export const StyledTableData = styled.td`
  padding: ${({$spaced}) => ($spaced ? '0.75rem 0' : '0')};
  text-align: ${({align}) => align || 'right'};
  font-weight: ${({$bold}) => ($bold ? 'bold' : 'normal')};
  padding-right: ${({$currency}) => ($currency ? '4px' : '0')};
`

export const StyledTableHeading = styled.th`
  padding: 0;
  width: 100%;
  text-align: left;
  font-weight: ${({$bold}) => ($bold ? 'bold' : 'normal')};
  font-style: ${({$italic}) => ($italic ? 'italic' : 'unset')};
`

export const StyledSteps = styled.ol`
  list-style-type: decimal;
  padding: 0 0 0 1rem;
  margin: 0.75rem 0 0 0;
`

export const StyledStep = styled.li`
  color: ${({theme}) => theme.colors.lunarGray60};
  font-size: 0.875rem;
  line-height: 1.2;
  margin-bottom: 0.25em;
`

export const StyledNote = styled.small`
  width: 100%;
  max-width: 100%;
  color: ${({theme}) => theme.colors.black};
  font-size: 0.875rem;
  font-style: italic;
  line-height: 1.2;
  margin: 1em 0 0 0;
`
