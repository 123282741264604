import {StyledSvg} from './styles'

function ArrowRightIcon({size, color, width, height, ...props}) {
  return (
    <StyledSvg
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      $size={size}
      $width={width}
      $height={height}
      {...props}
    >
      <path
        d="M1 8h14m0 0L8 1m7 7-7 7"
        stroke={color || '#000'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSvg>
  )
}

export default ArrowRightIcon
