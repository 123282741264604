import {
  Children,
  cloneElement,
  isValidElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'

const StyledMap = styled.div`
  width: ${({$width}) => $width ?? '100%'};
  max-width: 100%;
  height: ${({$height}) => $height ?? '100%'};
`

function GoogleMap({
  width,
  height,
  onClick,
  onIdle,
  children,
  style,
  ...options
}) {
  const ref = useRef(null)
  const [map, setMap] = useState()

  const eventsMap = useCallback(
    () => ({
      click: onClick,
      idle: onIdle,
    }),
    [onClick, onIdle],
  )

  const removeMapListener = useCallback(
    event => {
      window.google.maps.event.clearListeners(map, event)
    },
    [map],
  )

  const addMapListener = useCallback(
    (event, handler) => {
      if (handler) {
        map.addListener(event, handler)
      }
    },
    [map],
  )

  const resetMapListener = useCallback(
    (event, handler) => {
      removeMapListener(event)
      addMapListener(event, handler)
    },
    [addMapListener, removeMapListener],
  )

  useEffect(() => {
    if (map) {
      map.setOptions(options)

      Object.entries(eventsMap()).map(([event, handler]) => {
        resetMapListener(event, handler)
      })
    } else if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}))
    }
  }, [eventsMap, map, options, resetMapListener])

  const renderValidChildren = useCallback(
    () =>
      Children.map(children, child =>
        isValidElement(child) ? cloneElement(child, {map}) : null,
      ),
    [children, map],
  )

  return (
    <StyledMap ref={ref} $width={width} $height={height} style={style}>
      {renderValidChildren()}
    </StyledMap>
  )
}

export default GoogleMap
