import {useCallback} from 'react'
import {useField, useFormikContext} from 'formik'
import {useTranslation} from 'react-i18next'
import countries from 'i18n-iso-countries'
import {StyledSelect, StyledSelectWrapper} from './styles'
import {StyledFieldError} from '../../../../../../styles/common'
import {setCustomFormikFieldValue} from '../../../../../../utils/functions'

export default function CountryField({name, ...props}) {
  const {i18n} = useTranslation()
  const {setFieldValue, setFieldTouched} = useFormikContext()
  const [field, meta] = useField({name})

  const countryOptions = useCallback(() => {
    const localizedCountries = countries.getNames(i18n.language, {
      select: 'official',
    })
    const options = Object.entries(localizedCountries).map(
      ([value, label]) => ({value, label}),
    )

    return options
  }, [i18n.language])

  const onChange = useCallback(
    value => {
      setCustomFormikFieldValue({
        name,
        value,
        setFieldValue,
        setFieldTouched,
      })
    },
    [name, setFieldTouched, setFieldValue],
  )

  const onBlur = useCallback(
    () => setFieldTouched(name, true),
    [name, setFieldTouched],
  )

  const error = useCallback(
    () => meta.touched && meta.error,
    [meta.error, meta.touched],
  )

  return (
    <StyledSelectWrapper>
      <StyledSelect
        name={name}
        options={countryOptions()}
        value={field.value}
        onChange={onChange}
        onBlur={onBlur}
        $error={!!error()}
        {...props}
      />
      <StyledFieldError>{error()}</StyledFieldError>
    </StyledSelectWrapper>
  )
}
