import styled from 'styled-components'

export const PopularSuggestionText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${({theme}) => theme.colors.black};
  align-self: flex-start;
  margin-top: 29px;
`
export const PopularSuggestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 14px;
`
export const SuggestionCardWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  z-index: 0;

  & .popular-suggestion-slide {
    width: fit-content;
  }
`
export const OfficesListingContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 36px;
  padding-bottom: 0.5rem;
  width: 100%;
  gap: 16px;
`
export const OfficesNoResult = styled.p`
  text-align: center;
`
