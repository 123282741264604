import {useCallback} from 'react'
import {useField} from 'formik'
import {StyledInput, StyledLabel, StyledWrapped} from './styles'
import {StyledFieldError} from '../../styles/common'

function TextField({label, ...props}) {
  const [field, meta] = useField(props)

  const error = useCallback(
    () => meta.touched && meta.error,
    [meta.error, meta.touched],
  )

  return (
    <StyledWrapped>
      <StyledLabel htmlFor={field.name}>{label}</StyledLabel>
      <StyledInput $error={!!error()} {...field} {...props} />
      <StyledFieldError>{error()}</StyledFieldError>
    </StyledWrapped>
  )
}

export default TextField
