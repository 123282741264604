import styled, {css} from 'styled-components'

export const headingStyles = css`
  color: ${({theme}) => theme.colors.black};
  text-align: start;
  width: 100%;
  margin: 0;
  padding: 0;
  line-height: 1.3;
  font-weight: 700;
`

export const PageHeading1 = styled.h1`
  font-size: 1.5rem;
  ${headingStyles}
`

export const PageHeading2 = styled.h1`
  font-size: 2rem;
  ${headingStyles}
`

export const StyledSearchContainer = styled.div`
  width: 100%;
  position: sticky;
  padding: 0.5rem 0 0 0;
  top: 50px;
  background-color: ${({theme}) => theme.colors.white};
  box-shadow: ${({theme}) =>
    `16px 0 0 ${theme.colors.white}, -16px 0 0 ${theme.colors.white}`};
  z-index: 1;

  & > * {
    box-shadow: 16px 0 12px ${({theme}) => theme.colors.white},
      -16px 0 12px ${({theme}) => theme.colors.white},
      0 16px 16px ${({theme}) => theme.colors.white};
  }
`

export const ellipsisStyles = css`
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const waveBackgroundStyles = css`
  background-image: url('/images/wave-bg.svg');
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: contain;

  @media only screen and (min-width: 35em) {
    background-image: none;
  }
`

export const formInputStyles = css`
  border: 1px solid
    ${({theme, $error}) => theme.colors[$error ? 'red0' : 'lunarGray40']};
  border-radius: ${({theme}) => theme.rounded1};
  padding: ${({$large}) => ($large ? '16px 12px' : '5px 12px')};
  color: ${({theme}) => theme.colors.black};
  width: ${({$width}) => $width || '100%'};
  max-width: 100%;

  &:focus-visible {
    outline: 1px solid
      ${({theme, $error}) => theme.colors[$error ? 'red0' : 'black']};
    border: 1px solid
      ${({theme, $error}) => theme.colors[$error ? 'red0' : 'black']};
  }

  &::placeholder {
    color: ${({theme}) => theme.colors.lunarGray30};
    opacity: 1;
  }
`

export const StyledFieldError = styled.div`
  text-align: left;
  padding-left: ${({$paddingLeft}) => $paddingLeft || '12px'};
  width: 100%;
  max-width: 100%;
  font-size: 12px;
  color: ${({theme}) => theme.colors.red0};
`

export const StyledLoader = styled.div`
  display: inline-block;
  width: ${({$size}) => $size || '50px'};
  height: ${({$size}) => $size || '50px'};
  border: 3px solid ${({theme}) => theme.colors.white};
  border-radius: 50%;
  border-top-color: ${({theme}) => theme.colors.darkBlue};
  animation: spin 1s ease-in-out infinite;
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`

export const categoryCardSize = '6rem'
