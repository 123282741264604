import {useCallback} from 'react'
import PackageCard from '../PackageCard'
import {StyledPackagesWrapper} from './styles'

export default function PackageCards({packages, ...props}) {
  const renderPackages = useCallback(
    () =>
      packages.map(pkg => (
        <PackageCard
          key={pkg._id}
          value={pkg._id}
          packageType={pkg.name}
          description={pkg.description}
          price={pkg.price}
          currency={pkg.currency}
          backgroundColor={pkg.color}
          {...props}
        />
      )),
    [packages, props],
  )

  return <StyledPackagesWrapper>{renderPackages()}</StyledPackagesWrapper>
}
