import {useCallback, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useTheme} from 'styled-components'
import {CSSTransition} from 'react-transition-group'
import Clickable, {Anchor, Button} from '../Clickable'
import Icon from '../Icon'
import {menuItems, socialLinks} from './data'
import {
  StyledClickable,
  StyledContent,
  StyledFooter,
  StyledGreetings,
  StyledLink,
  StyledMenu,
  StyledMenuContainer,
  StyledTop,
} from './styles'

function Menu({defaultOpen = false}) {
  const theme = useTheme()
  const {t} = useTranslation()
  const transitionRef = useRef()
  const [open, setOpen] = useState(defaultOpen)

  const toggleMenu = useCallback(() => setOpen(prevVal => !prevVal), [setOpen])

  const renderMenuItems = useCallback(
    () =>
      menuItems.map(item => (
        <StyledLink key={item.key} to={item.to}>
          {t(`menu:${item.labelKey}`)}
        </StyledLink>
      )),
    [t],
  )

  const renderSocialLinks = useCallback(
    () =>
      socialLinks.map(item => (
        <StyledClickable
          key={item.name}
          as={Anchor}
          href={item.href}
          target="_blank"
          title={t(`menu:${item.labelKey}`)}
        >
          <Icon name={item.name} size="2.5rem" />
        </StyledClickable>
      )),
    [t],
  )

  return (
    <StyledMenuContainer>
      <Clickable as={Button} title={t('common:menu')} onClick={toggleMenu}>
        <Icon name="hamburger" width="1.5rem" color={theme.colors.black} />
      </Clickable>
      <CSSTransition
        in={open}
        timeout={1000}
        classNames="bounce"
        nodeRef={transitionRef}
        unmountOnExit
      >
        <StyledMenu ref={transitionRef}>
          <StyledTop>
            <Clickable
              as={Button}
              title={t('common:close')}
              onClick={toggleMenu}
            >
              <Icon name="close" size="1.25rem" color={theme.colors.black} />
            </Clickable>
          </StyledTop>
          <StyledContent>
            <StyledGreetings>
              <Icon name="theFutur" size="3rem" />
              {t('menu:hello')}
            </StyledGreetings>
            {renderMenuItems()}
          </StyledContent>
          <StyledFooter>{renderSocialLinks()}</StyledFooter>
        </StyledMenu>
      </CSSTransition>
    </StyledMenuContainer>
  )
}

export default Menu
