import styled from 'styled-components'
import Select from 'react-select'

export const StyledSelectWrapper = styled.div`
  width: 100%;
  max-width: 100%;
`

export const StyledSelect = styled(Select)`
  --height: 3.625rem;

  width: ${({$width}) => $width || '100%'};
  max-width: 100%;
  font-size: 16px;
  height: 100%;
  min-height: var(--height);

  & div[class$='control'] {
    box-shadow: none;
    height: 100%;
    min-height: var(--height);
    border: 1px solid
      ${({theme, $error}) => theme.colors[$error ? 'red0' : 'lunarGray40']};
    border-radius: ${({theme}) => theme.rounded1};

    &:focus-within {
      outline: 1px solid
        ${({theme, $error}) =>
          `${theme.colors[$error ? 'red0' : 'black']} !important`};
      border: 1px solid
        ${({theme, $error}) =>
          `${theme.colors[$error ? 'red0' : 'black']} !important`};
    }

    & > div {
      height: 100%;
    }

    & > div:first-child {
      padding: 0 12px;
      height: 100%;
      min-height: var(--height);

      & > div[class$='placeholder'] {
        color: ${({theme}) => theme.colors.lunarGray30};
        opacity: 1;
        margin: 0;
      }

      & > div[class$='singleValue'] {
        color: inherit;
        margin: 0;
      }

      & > div:nth-child(2) {
        margin: 0;
      }
    }

    & > div:nth-child(2) {
      align-self: center;
    }
  }

  & [class$='-menu'] {
    & .group-heading-wrapper {
      position: relative;

      &::after {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='20' width='20' fill='%23B7B4B4' viewBox='0 0 20 20' aria-hidden='true' focusable='false' class='subcat-select-group-arrow'%3E%3Cpath d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z'%3E%3C/path%3E%3C/svg%3E");
        position: absolute;
        right: 8px;
        bottom: -4px;
      }
    }

    & .group-heading-wrapper + div {
      &:not(.group-heading-expanded) {
        display: none;
      }
    }
  }
`
