function sanitizeWhatsapp(number) {
  let sanitizeWhatsappNumbers = number

  if (number?.includes('+')) {
    sanitizeWhatsappNumbers = number.replace(/\+/g, '')
  }
  return sanitizeWhatsappNumbers
}

export default sanitizeWhatsapp
