import {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import {toast} from 'react-toastify'
import Card from '../../components/Card'
import {getCategories} from '../../services/api'
import {PageHeading1} from '../../styles/common'
import {CategoriesContainer, StyledLink} from './styles'
import DefaultLayout from '../../layouts/DefaultLayout/index'

function CategoriesPage() {
  const {t} = useTranslation()
  const [categories, setCategories] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    let setState = res => {
      setCategories(res)
      setIsLoading(false)
    }

    getCategories()
      .then(res => setState?.(res))
      .catch(e => {
        console.log('e :>> ', e)
        toast.error(t('common:errors.unknownRefresh'))
        setIsLoading(false)
      })

    return () => {
      setState = null
    }
  }, [t])

  const renderCategories = useCallback(
    () =>
      categories.map(category => {
        const image = category.image ? category.image : null

        return (
          <StyledLink
            as={Link}
            key={category._id}
            to={{
              pathname: `/categories/${category._id}`,
            }}
          >
            <Card title={category.label} alt={category.label} image={image} />
          </StyledLink>
        )
      }),
    [categories],
  )

  return (
    <DefaultLayout loading={isLoading}>
      <PageHeading1>{t('categoriesPage:heading1')}</PageHeading1>
      <CategoriesContainer>{renderCategories()}</CategoriesContainer>
    </DefaultLayout>
  )
}

export default CategoriesPage
