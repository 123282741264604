import styled from 'styled-components'
import {categoryCardSize} from '../../../styles/common'

export const CardWrapper = styled.div`
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 1rem;
  overflow: hidden;
  max-width: ${({$mini}) => ($mini ? '100%' : categoryCardSize)};
  min-width: ${({$mini}) => ($mini ? '100%' : categoryCardSize)};
  height: ${({$mini}) => ($mini ? 'auto' : '100%')};
`

export const ImageWrapper = styled.div`
  width: 100%;
  aspect-ratio: 1 / 1;
  height: ${({$height}) => $height};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CardImage = styled.img`
  width: 100%;
  height: ${categoryCardSize};
  object-fit: cover;
  text-decoration: none;
`

export const CardTitle = styled.p`
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 0.875rem;
  text-align: center;
  padding: 0.75rem;
  color: ${({theme}) => theme.colors.black};
  margin: 0;
  text-decoration: none;
  text-transform: capitalize;
  word-break: break-word;
`
