import {useTheme} from 'styled-components'
import React, {useCallback} from 'react'
import {
  SearchCardWrapper,
  SearchCardText,
  SearchCardGroup,
  SearchCardInitials,
  SearchCardLogo,
} from './styles'
import Clickable, {Button} from '../Clickable'
import Icon from '../Icon'
import ButtonGroup from '../ButtonGroup'
import CallWrapper from '../CallWrapper'
import {
  retrieveGoogleMapsUrl,
  sanitizePhoneNumbers,
} from '../../utils/functions'

function SearchCard({
  image,
  title,
  address,
  fullAddress,
  phones,
  hideImage,
  $initials,
  ...props
}) {
  const theme = useTheme()

  const includes = props?.includeKey

  const goToMaps = useCallback(
    e => {
      e?.preventDefault?.()

      window.open(retrieveGoogleMapsUrl(address))
    },
    [address],
  )

  const sanitizedPhoneNums = useCallback(
    () => sanitizePhoneNumbers(phones),
    [phones],
  )

  const noContact = useCallback(
    () => (sanitizedPhoneNums().length || 0) === 0,
    [sanitizedPhoneNums],
  )

  const phoneNumbers = useCallback(() => {
    const phoneNumsLength = sanitizedPhoneNums()?.length || 0

    if (phoneNumsLength === 1) return sanitizedPhoneNums()

    if (phoneNumsLength > 1) return [sanitizedPhoneNums()[0]]

    return []
  }, [sanitizedPhoneNums])

  const renderPlaceholder = useCallback(() => {
    if (hideImage) return null

    if (includes?.logo && image) {
      return <img src={image} alt="company_logo" width={65} height={72} />
    }
    if ($initials) {
      return <SearchCardInitials>{$initials}</SearchCardInitials>
    }
    return (
      <SearchCardLogo>
        <Icon name="theFutur" size="1.875rem" />
      </SearchCardLogo>
    )
  }, [$initials, hideImage, image, includes?.logo])

  const renderPhoneNumbers = useCallback(() => {
    if (noContact()) return null

    return <span>T:{sanitizedPhoneNums().join('/')}</span>
  }, [noContact, sanitizedPhoneNums])

  return (
    <SearchCardWrapper {...props}>
      <SearchCardGroup>
        {renderPlaceholder()}

        <SearchCardText>
          <p>{title}</p>

          <span>{fullAddress}</span>

          {renderPhoneNumbers()}
        </SearchCardText>
      </SearchCardGroup>
      <ButtonGroup $roundedEnd={includes?.location}>
        {!!(includes?.location && address?.lat && address?.lng) && (
          <Clickable
            as={Button}
            $variant="danger"
            width="4rem"
            className="btn-directions"
            $large
            onClick={goToMaps}
          >
            <Icon name="marker" size="1.5em" color={theme.colors.white} />
          </Clickable>
        )}

        {!!includes?.call && (
          <CallWrapper phoneNumbers={phoneNumbers()}>
            <Clickable
              as={Button}
              $variant="success"
              width="4rem"
              $large
              disabled={noContact()}
              $roundedEnd
            >
              <Icon name="phone" size="1.5em" color={theme.colors.white} />
            </Clickable>
          </CallWrapper>
        )}
      </ButtonGroup>
    </SearchCardWrapper>
  )
}

export default SearchCard
