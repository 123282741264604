import {StyledSvg} from './styles'

function TheFuturIcon({size, color, width, height, ...props}) {
  return (
    <StyledSvg
      viewBox="0 0 89 89"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      $size={size}
      $width={width}
      $height={height}
      {...props}
    >
      <g clipPath="url(#vector_svg__the_futur_a)">
        <path
          d="M76.364 13.436A44.5 44.5 0 1 0 43.94 89h.57a44.5 44.5 0 0 0 31.854-75.564ZM44.49 2.001h.55A42.479 42.479 0 0 1 87 45.02c0 .58 0 1.16-.07 1.74a56.157 56.157 0 0 1-3.883 7.443 20.409 20.409 0 0 1-8.614 6.873c-7.673 3.132-15.006.2-19.408-1.55-6.093-2.431-9.174-5.423-12.426-8.594a48.522 48.522 0 0 0-7.053-6.003c-8.384-5.612-19.499-7.593-33.075-5.873h-.09A42.55 42.55 0 0 1 44.49 2.002ZM33.225 57.256a57.211 57.211 0 0 0 10.345 7.313c6.263 3.471 12.395 5.242 18.008 5.242 1.68.01 3.357-.158 5.002-.5a23.375 23.375 0 0 0 6.873-2.601 27.382 27.382 0 0 1-12.145 6.003C43.67 76.314 26.422 60.577 26.292 60.467c-9.004-9.614-16.007-16.117-20.5-19.178 5.153.7 14.587 3.851 27.433 15.967Zm-21.89-16.698c9.184 0 16.938 2.001 23.07 6.133a47.027 47.027 0 0 1 6.764 5.742c3.261 3.172 6.623 6.443 13.085 9.005 4.672 1.86 12.486 5.002 20.9 1.54l.53-.22a22.34 22.34 0 0 1-9.514 4.572c-15.517 3.212-31.414-11.405-31.574-11.555-9.685-9.144-17.558-13.336-23.26-15.217Zm13.526 21.34a59.946 59.946 0 0 0 11.285 7.823c6.793 3.662 13.516 5.533 19.75 5.533a28.827 28.827 0 0 0 5.772-.57c8.003-1.631 14.916-6.653 20.71-14.957a37.278 37.278 0 0 1-11.496 12.596c-13.276 9.064-27.352 6.362-31.274 5.332A55.025 55.025 0 0 1 21.4 67.35c-5.823-5.002-9.294-10.204-12.356-14.786a94.232 94.232 0 0 1-6.193-10.725c2.471 1.3 8.494 5.622 22.01 20.059ZM43.96 86.999A42.529 42.529 0 0 1 2.02 44.63a97.848 97.848 0 0 0 5.342 9.064c3.142 4.692 6.683 10.005 12.726 15.197a57.025 57.025 0 0 0 18.929 10.705 41.937 41.937 0 0 0 10.344 1.26 39.787 39.787 0 0 0 22.64-6.853 39.089 39.089 0 0 0 10.825-11.135A42.55 42.55 0 0 1 44.51 87h-.55Z"
          fill="url(#vector_svg__the_futur_b)"
        />
        <path
          d="M75.674 26.492c5.945 0 10.765-4.632 10.765-10.345 0-5.713-4.82-10.344-10.765-10.344s-10.765 4.631-10.765 10.344c0 5.713 4.82 10.345 10.765 10.345Z"
          fill={color || '#231F5E'}
        />
      </g>
      <defs>
        <linearGradient
          id="vector_svg__the_futur_b"
          x1={-0.39}
          y1={43.68}
          x2={89.42}
          y2={45.33}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color || '#76D0EE'} />
          <stop offset={1} stopColor={color || '#231F5E'} />
        </linearGradient>
        <clipPath id="vector_svg__the_futur_a">
          <path fill="#fff" d="M0 0h89v89H0z" />
        </clipPath>
      </defs>
    </StyledSvg>
  )
}

export default TheFuturIcon
