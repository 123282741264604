const colors = {
  white: '#FFFFFF',
  black: '#000000',
  lunarGray70: '#232323',
  lunarGray60: '#868686',
  lunarGray50: '#969696',
  lunarGray40: '#A0A0A0',
  lunarGray30: '#B7B4B4',
  lunarGray20: '#EBEBEB',
  lunarGray10: '#F4F4F4',
  lunarGray0: '#FBFBFB',
  ashGray: '#92A0A9',
  darkBlue: '#231F5E',
  blue20: '#263238',
  blue10: '#3B5998',
  blue0: '#9CABC2',
  lightBlue10: '#42B9E1',
  lightBlue0: '#76CFED',
  green: '#69BF5B',
  red20: '#DA3B3B',
  red10: '#FF0000',
  red0: '#F95454',
  pink: '#FFE9E9',
}

export default colors
