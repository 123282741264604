import {StyledSvg} from './styles'

function HamburgerIcon({size, color, width, height, ...props}) {
  return (
    <StyledSvg
      viewBox="0 0 28 16"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      $size={size}
      $width={width}
      $height={height}
      {...props}
    >
      <path
        d="M15.333 13.333H2A1.333 1.333 0 0 0 2 16h13.333a1.333 1.333 0 0 0 0-2.667ZM2 2.667h24A1.333 1.333 0 1 0 26 0H2a1.333 1.333 0 0 0 0 2.667Zm24 4H2a1.333 1.333 0 1 0 0 2.666h24a1.333 1.333 0 1 0 0-2.666Z"
        fill={color || '#000'}
      />
    </StyledSvg>
  )
}

export default HamburgerIcon
