import styled from 'styled-components'
import {ellipsisStyles} from '../../../styles/common'

const SearchCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: ${({$premium, theme}) =>
    $premium ? theme.colors.black : theme.colors.lunarGray0};
  color: ${({$premium, theme}) =>
    $premium ? theme.colors.white : theme.colors.black};
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: ${({theme}) => theme.rounded1};
  width: 100%;
  max-width: 100%;
  height: 4.5rem;

  & .btn-directions {
    display: none;

    @media (min-width: 22em) {
      display: block;
    }
  }
`

const SearchCardText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.75rem 1rem;
  min-width: 0;

  p {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    ${ellipsisStyles}
  }

  span {
    display: inline-block;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    ${ellipsisStyles}
  }
`

const SearchCardGroup = styled.div`
  display: flex;
  border-radius: ${({theme}) => `${theme.rounded1} 0 0 ${theme.rounded1}`};
  overflow: clip;
  min-width: 0;

  & img {
    word-break: break-all;
    color: transparent;
  }
`

const SearchCardInitials = styled.div`
  color: ${({theme}) => theme.colors.black};
  background: ${({theme}) => theme.colors.lunarGray0};
  width: 65px;
  min-width: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
`

const SearchCardLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  min-width: 65px;
  height: 100%;
  background-color: ${({theme}) => theme.colors.lunarGray10};
`

export {
  SearchCardWrapper,
  SearchCardText,
  SearchCardGroup,
  SearchCardInitials,
  SearchCardLogo,
}
