import {StyledSvg} from './styles'

function LinkedInIcon({size, color, width, height, ...props}) {
  return (
    <StyledSvg
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      $size={size}
      $width={width}
      $height={height}
      {...props}
    >
      <circle cx={20} cy={20} r={20} fill={color || '#0077B5'} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.775 10.284c0 1.245-.948 2.253-2.43 2.253-1.426 0-2.374-1.008-2.344-2.253C9.97 8.978 10.919 8 12.373 8c1.454 0 2.373.978 2.402 2.284ZM10.12 28.819V14.316h4.507v14.502H10.12v.001ZM18.24 18.945c0-1.81-.06-3.351-.12-4.627h3.916l.208 1.987h.089c.593-.92 2.075-2.312 4.478-2.312 2.965 0 5.189 1.957 5.189 6.226v8.602h-4.507v-8.037c0-1.87-.652-3.144-2.283-3.144-1.246 0-1.987.86-2.283 1.69-.12.297-.178.711-.178 1.127v8.364h-4.507v-9.876h-.002Z"
        fill="#fff"
      />
    </StyledSvg>
  )
}

export default LinkedInIcon
