const menuItems = [
  {
    key: 'businessListings',
    labelKey: 'businessListings',
    to: '/categories',
  },
  {
    key: 'registerBusiness',
    labelKey: 'registerBusiness',
    to: '/registration',
  },
  {
    key: 'privacy',
    labelKey: 'privacy',
    to: '/privacy',
  },
  {
    key: 'terms-and-conditions/end-user',
    labelKey: 'termsAndConditionsEndUser',
    to: '/terms-and-conditions/end-user',
  },
]

const socialLinks = [
  {
    name: 'instagram',
    labelKey: 'instagram',
    href: 'https://www.instagram.com/thefuturapp',
  },
  {
    name: 'linkedIn',
    labelKey: 'linkedIn',
    href: 'https://www.linkedin.com/company/thefutur',
  },
  {
    name: 'facebook',
    labelKey: 'facebook',
    href: 'https://www.facebook.com/thefuturapp',
  },
  {
    name: 'whatsApp',
    labelKey: 'whatsApp',
    href: 'https://api.whatsapp.com/send?phone=23055002555',
  },
]

export {menuItems, socialLinks}
