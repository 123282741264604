import {useField} from 'formik'
import {useCallback} from 'react'
import {StyledFieldError} from '../../../../../../styles/common'
import {StyledTimeField, StyledTimeFieldWrapper} from './styles'

export default function TimeField({name, day, ...props}) {
  const [, meta] = useField(name)

  const error = useCallback(
    () => meta.touched && meta.error,
    [meta.error, meta.touched],
  )

  return (
    <StyledTimeFieldWrapper>
      <StyledTimeField
        name={name}
        {...props}
        type="time"
        $error={error()}
        required
      />
      <StyledFieldError>{error()}</StyledFieldError>
    </StyledTimeFieldWrapper>
  )
}
