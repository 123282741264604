import {Trans, useTranslation} from 'react-i18next'
import DefaultLayout from '../../layouts/DefaultLayout'
import {PageHeading1} from '../../styles/common'
import {StyledH2, StyledList, StyledListItem} from './styles'

export default function PrivacyPage() {
  const {t} = useTranslation()

  return (
    <DefaultLayout loading={false}>
      <PageHeading1>{t('privacyPage:title')}</PageHeading1>
      <Trans
        components={{
          heading: <StyledH2 />,
          list: <StyledList />,
          'list-item': <StyledListItem />,
        }}
      >
        {t('privacyPage:content')}
      </Trans>
    </DefaultLayout>
  )
}
